import {
  Box,
  Button,
  Center,
  Collapse,
  FormControl,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { FaPhone, FaRegEnvelope } from "react-icons/fa6";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  GoogleLoginButton,
  SpotifyLoginButton,
} from "src/components/Auth/LoginButtons";
import ProfilePictureUpload from "src/components/User/UploadProfilePic";
import { phoneRegex } from "src/constants";
import { checkUser } from "src/networking";
import { useSignUp, useUpdateMe } from "src/state/userQueries";
import { errorToast, useLogout } from "src/utils";
import { BackButton } from "../Buttons";
import { PasswordInput } from "../Forms";
import { AppIconLogo } from "../Header/Header";
import ScreenshotSlider from "./ScreenshotSlider";
import VerifyPhone from "./VerifyPhoneInput";
import { minifyPhoneNumber } from "./verifyPhoneUtils";

enum SignUpStep {
  SELECT_METHOD,
  EMAIL,
  ENTER_PHONE,
  VERIFY_PHONE,
  NAME_AND_PHOTO,
  USERNAME,
}

interface SignUpProps {
  usernameNotSet: boolean;
  firstName?: string;
  lastName?: string;
  googleProfilePicture?: string;
}

const SignUpForm: React.FC<SignUpProps> = ({
  usernameNotSet,
  firstName,
  lastName,
  googleProfilePicture,
}) => {
  const [firstNameState, setFirstName] = useState(firstName || "");
  const [lastNameState, setLastName] = useState(lastName || "");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [show2ndPassword, setShow2ndPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentStep, setCurrentStep] = useState<SignUpStep>(
    usernameNotSet ? SignUpStep.NAME_AND_PHOTO : SignUpStep.SELECT_METHOD
  );
  const [profilePicture, setProfilePicture] = useState<File | undefined>(
    undefined
  );
  const [firebaseToken, setFirebaseToken] = useState("");
  const [loading, setLoading] = useState(false);
  const signUpWithPhoneEnabled = useFeatureFlagEnabled("signUpWithPhone");

  const handleLogout = useLogout();
  const toast = useToast();
  const navigate = useNavigate();
  const signUp = useSignUp();
  const updateMe = useUpdateMe();

  useEffect(() => {
    if (usernameNotSet) {
      setCurrentStep(SignUpStep.NAME_AND_PHOTO);
    }
  }, [usernameNotSet]);

  const nextStep = () => {
    if (currentStep === SignUpStep.SELECT_METHOD) {
      setCurrentStep(SignUpStep.EMAIL);
    } else if (currentStep === SignUpStep.EMAIL) {
      setCurrentStep(SignUpStep.NAME_AND_PHOTO);
    } else if (currentStep === SignUpStep.ENTER_PHONE) {
      setCurrentStep(SignUpStep.VERIFY_PHONE);
    } else if (currentStep === SignUpStep.VERIFY_PHONE) {
      setCurrentStep(SignUpStep.NAME_AND_PHOTO);
    } else if (currentStep === SignUpStep.NAME_AND_PHOTO) {
      setCurrentStep(SignUpStep.USERNAME);
    }
  };

  const prevStep = () => {
    if (currentStep === SignUpStep.EMAIL) {
      setCurrentStep(SignUpStep.SELECT_METHOD);
    } else if (currentStep === SignUpStep.ENTER_PHONE) {
      setCurrentStep(SignUpStep.SELECT_METHOD);
    } else if (currentStep === SignUpStep.VERIFY_PHONE) {
      setCurrentStep(SignUpStep.ENTER_PHONE);
    } else if (currentStep === SignUpStep.NAME_AND_PHOTO) {
      if (!!email) {
        setCurrentStep(SignUpStep.EMAIL);
      } else if (!!phone) {
        setCurrentStep(SignUpStep.VERIFY_PHONE);
      } else {
        setCurrentStep(SignUpStep.SELECT_METHOD);
      }
    } else if (currentStep === SignUpStep.USERNAME) {
      setCurrentStep(SignUpStep.NAME_AND_PHOTO);
    }
  };

  const handleSignup = async () => {
    if (loading) return;
    setLoading(true);
    let signUpPromise;
    if (usernameNotSet) {
      signUpPromise = updateMe.mutateAsync({
        firstName: firstNameState,
        lastName: lastNameState,
        username,
        profilePicture,
      });
    } else if (firebaseToken) {
      signUpPromise = signUp.mutateAsync({
        firstName: firstNameState,
        lastName: lastNameState,
        username,
        profilePicture,
        phone,
        firebaseToken,
      });
    } else {
      signUpPromise = signUp.mutateAsync({
        firstName: firstNameState,
        lastName: lastNameState,
        username,
        profilePicture,
        email,
        password,
      });
    }
    signUpPromise
      .then((_user) => {
        setLoading(false);
        toast({
          title: "Welcome to Podiums!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/me", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          errorToast(error.response.data.message, toast, "top");
        } else {
          // If the request was not successful, handle the error
          if (process.env.REACT_APP_DEBUG) {
            console.error("Error during authentication:", error);
          }
          errorToast("Error authenticating", toast, "top");
        }
      });
  };

  if (currentStep === SignUpStep.SELECT_METHOD) {
    return (
      <Center minH="90vh" w="100%">
        <HStack spacing="3em" width={{ base: "90%", md: "auto" }}>
          <Center display={{ base: "none", md: "block" }} px="2em">
            <ScreenshotSlider />
          </Center>
          <Center w="100%">
            <VStack
              spacing={4}
              width={{ base: "100%", md: "350px" }}
              flex={1}
              maxW={{
                base: "450px",
                md: "350px",
              }}
            >
              <AppIconLogo />
              <Heading
                fontSize="36px"
                mb={2}
                color="topFive.primary"
                textAlign="center"
                fontWeight={600}
              >
                Sign up
              </Heading>
              <VStack spacing={3} w="100%">
                <SpotifyLoginButton />
                <GoogleLoginButton />
                <Button
                  w="100%"
                  onClick={() => setCurrentStep(SignUpStep.EMAIL)}
                  leftIcon={<FaRegEnvelope />}
                  variant="light"
                  borderRadius="full"
                >
                  Sign up with email
                </Button>
                {signUpWithPhoneEnabled && (
                  <Button
                    w="100%"
                    onClick={() => setCurrentStep(SignUpStep.ENTER_PHONE)}
                    leftIcon={<FaPhone />}
                    variant="light"
                    borderRadius="full"
                  >
                    Sign up with phone
                  </Button>
                )}
              </VStack>
              <HStack mt="1em">
                <Text fontSize="16px">Already have an account? </Text>
                <Button
                  as={RouterLink}
                  to="/login"
                  borderRadius="full"
                  size="sm"
                  bg="white"
                >
                  Log in
                </Button>
              </HStack>
            </VStack>
          </Center>
        </HStack>
      </Center>
    );
  } else if (currentStep === SignUpStep.EMAIL) {
    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const res = await checkUser(email);
          if (res.success) {
            errorToast(
              "An account with that email already exists",
              toast,
              "top"
            );
          } else if (password !== password2) {
            errorToast("Passwords do not match", toast, "top");
          } else {
            nextStep();
          }
        }}
        style={{ width: "100%" }}
      >
        <Center minH="85vh" w="100%">
          <VStack spacing={4} w="100%" maxW="450px">
            <AppIconLogo />
            <BackButton onClick={prevStep} text="Back" mt="1em" />
            <Heading
              fontSize="30px"
              mb={2}
              color="topFive.primary"
              fontWeight={600}
              w="100%"
            >
              Let's get started.
            </Heading>
            <FormControl>
              <InputGroup>
                <InputLeftAddon bg="white" pl="12px" pr={0}>
                  <Icon as={FaRegEnvelope} />
                </InputLeftAddon>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                  autoCapitalize="none"
                  spellCheck="false"
                  variant="filled"
                  pl="10px"
                  autoFocus
                />
              </InputGroup>
            </FormControl>

            <PasswordInput
              password={password}
              setPassword={(v) => {
                setPassword(v);
                setShow2ndPassword(v !== "");
              }}
            />
            {
              <Collapse
                in={show2ndPassword}
                unmountOnExit
                style={{ width: "100%", paddingTop: "0.5em" }}
              >
                <PasswordInput
                  password={password2}
                  setPassword={setPassword2}
                  label="Confirm password"
                />
              </Collapse>
            }
            <Button w="100%" type="submit" variant="dark" borderRadius="full">
              Continue
            </Button>
          </VStack>
        </Center>
      </form>
    );
  } else if (currentStep === SignUpStep.ENTER_PHONE) {
    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!phoneRegex.test(minifyPhoneNumber(phone))) {
            errorToast("Invalid phone number", toast, "top");
          } else if ((await checkUser(minifyPhoneNumber(phone))).success) {
            errorToast(
              "An account with that phone number already exists",
              toast,
              "top"
            );
          } else {
            nextStep();
          }
        }}
        style={{ width: "100%" }}
      >
        <Center minH="85vh" w="100%">
          <VStack spacing={4} w="100%" maxW="450px">
            <BackButton onClick={prevStep} text="Back" mt="1em" />
            <Heading
              fontSize="30px"
              mb={2}
              color="topFive.primary"
              fontWeight={600}
              w="100%"
            >
              Can I get your number?
            </Heading>
            {signUpWithPhoneEnabled ? (
              <>
                <FormControl>
                  <InputGroup>
                    <InputLeftAddon bg="white" pl="12px" pr={0}>
                      <Icon as={FaPhone} />
                    </InputLeftAddon>
                    <Input
                      type="phone"
                      value={phone}
                      placeholder="Phone"
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      variant="filled"
                    />
                  </InputGroup>
                </FormControl>
                <Button
                  w="100%"
                  variant="dark"
                  type="submit"
                  borderRadius="full"
                >
                  Continue
                </Button>
              </>
            ) : (
              <Text>
                Phone sign up is temporarily disabled. Please sign up with
                email, or login directly with Spotify/Google.
              </Text>
            )}
          </VStack>
        </Center>
      </form>
    );
  } else if (currentStep === SignUpStep.VERIFY_PHONE) {
    // phone
    return (
      <Center minH="90vh" w="100%">
        <Box maxW="450px">
          <VerifyPhone
            phoneNumber={minifyPhoneNumber(phone)}
            setFirebaseToken={(v) => {
              setFirebaseToken(v);
              nextStep();
            }}
            onBack={() => {
              setFirebaseToken("");
              prevStep();
            }}
          />
        </Box>
      </Center>
    );
  } else if (currentStep === SignUpStep.NAME_AND_PHOTO) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          nextStep();
        }}
        style={{ width: "100%" }}
      >
        <Center minH="90vh" w="100%">
          <VStack spacing={4} w="100%" maxW="450px">
            <BackButton onClick={prevStep} text="Back" mt="1em" />
            <Heading
              fontSize="30px"
              color="topFive.primary"
              fontWeight={600}
              w="100%"
              mb={-2}
            >
              Smile for the camera
            </Heading>
            <Heading
              fontSize="16px"
              mb={2}
              color="gray.600"
              fontWeight={500}
              w="100%"
            >
              Profile photo and name, please.
            </Heading>
            <ProfilePictureUpload
              setProfilePicture={setProfilePicture}
              existingProfilePicture={googleProfilePicture}
              size={5}
            />
            <VStack spacing={0} width="100%">
              <FormControl>
                <Input
                  type="text"
                  value={firstNameState}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First name"
                  required
                  autoFocus={!usernameNotSet}
                  variant="filled"
                  borderBottomRadius={0}
                  borderBottomWidth="1px"
                  borderBottomColor="gray.300"
                />
              </FormControl>
              <FormControl>
                <Input
                  type="text"
                  value={lastNameState}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last name"
                  required
                  variant="filled"
                  borderTopRadius={0}
                />
              </FormControl>
            </VStack>
            <Button
              w="100%"
              variant="dark"
              type="submit"
              isLoading={loading}
              borderRadius="full"
              color="white"
            >
              Continue
            </Button>
            {usernameNotSet && (
              <HStack mt="1em">
                <Text>Made a mistake?</Text>
                <Button onClick={handleLogout} borderRadius="full">
                  Logout
                </Button>
              </HStack>
            )}
          </VStack>
        </Center>
      </form>
    );
  } else if (currentStep === SignUpStep.USERNAME) {
    const inputStyle = {
      border: "1px solid inherit",
      borderLeft: "none",
    };
    return (
      <form
        onSubmit={(e) => {
          handleSignup();
          e.preventDefault();
        }}
        style={{ width: "100%" }}
      >
        <Center minH="90vh" w="100%">
          <VStack spacing={4} w="100%" alignItems="start" maxW="450px">
            <BackButton onClick={prevStep} text="Back" width="100px" />
            <VStack spacing={4} w="100%" alignItems="left">
              <Heading
                fontSize="30px"
                color="topFive.primary"
                fontWeight={600}
                w="100%"
                mb={-2}
              >
                Claim your username
              </Heading>
              <Heading
                fontSize="16px"
                mb={2}
                color="gray.600"
                fontWeight={500}
                w="100%"
              >
                This will be your unique Podiums link.
              </Heading>
              <FormControl>
                <InputGroup>
                  <InputLeftAddon
                    bg="#ffffff"
                    borderRight="none"
                    pr={1}
                    color="gray.500"
                  >
                    podiu.ms/
                  </InputLeftAddon>
                  <Input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="username"
                    required
                    autoFocus={usernameNotSet}
                    autoCapitalize="off"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                    borderLeft="none"
                    pl={0.5}
                    _focus={inputStyle}
                    _focusVisible={inputStyle}
                    _hover={inputStyle}
                  />
                </InputGroup>
                {/* <FormLabel>Username</FormLabel> */}
              </FormControl>
              <Button
                w="100%"
                variant="dark"
                type="submit"
                isLoading={loading}
                borderRadius="full"
              >
                Sign Up
              </Button>
              {usernameNotSet && (
                <HStack mt="1em">
                  <Text fontSize="16px">Made a mistake? </Text>
                  <Button onClick={handleLogout} size="sm" borderRadius="full">
                    Logout
                  </Button>
                </HStack>
              )}
            </VStack>
          </VStack>
        </Center>
      </form>
    );
  } else {
    throw new Error("Unimplemented step number");
  }
};

export default SignUpForm;
