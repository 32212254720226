import { ListItem, UserList } from "./list";
import { Pin } from "./pin";

export interface OAuthIntegrationProvider {
  name: string;
  slug: string;
  color: string;
  clientID: string;
  scope: string;
  authorizeURL: string;
  tokenURL: string;
  myItemsURL: string;
}

export interface OAuthIntegration {
  accessToken: string;
  _id: string;
  providerSlug: string;
}

export const AllBackgroundTypes: string[] = [
  "party-gradient",
  "black",
  "white",
  "blue-gradient",
  "warm-gradient",
  "purple-gradient",
  "rainbow-candy-gradient",
  "pink-lemonade-gradient",
  "coffee",
  "sage",
  "rainbow-spiral-image",
  "black-galaxy-image",
  "trippy-rainbow-image",
  "alien-image",
];

export interface User {
  _id: string;
  firstName?: string;
  lastName?: string;
  profilePicture?: string;
  email?: string;
  phone?: string;
  username: string;
  password?: string;
  lists: UserList[];
  pins?: Pin[];
  diffs: ListDiff[];
  oauthIntegrations: OAuthIntegration[];
  following: any[];
  followers: any[];
  backgroundType?: string;
  isLoading?: boolean;
  isAdmin?: boolean;
  accessToken?: string;
  refreshToken?: string;
  updatedAt: string;
  emailVerified?: boolean;
  loginMethod?: string;
  emailVerificationSent?: string; // date in iso time
  passwordResetSent?: string; // date in iso time
  views?: number;
  waitlist?: boolean;
  listLayout: string[][];
}

export interface SimpleUser {
  _id: string;
  firstName?: string;
  lastName?: string;
  username: string;
  profilePicture?: string;
  lists?: UserList[];
  updatedAt: string;
}

export interface SanitizedOtherUser extends SimpleUser {
  lists: UserList[];
  backgroundType?: string;
  views?: number;
  pins?: Pin[];
  listLayout: string[][];
}

export interface DiffItem {
  value: ListItem;
  change: number;
}

export interface ListDiff {
  user?: SanitizedOtherUser;
  listName: string;
  diffItems: DiffItem[];
  updatedAt: string;
}
