import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Image,
  Tag,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import { FaHeadphones, FaPlus, FaXmark } from "react-icons/fa6";
import { TiPin } from "react-icons/ti";
import { Link } from "react-router-dom";
import { borderRadiusDisabledUsers } from "src/constants";
import { useDeletePin } from "src/state/userQueries";
import { Pin } from "src/types/pin";
import PodiumsCard from "../PodiumsCard";
import { PodiumsDialog } from "../PodiumsDialog";
import { EditPinOptions } from "./EditPinOptions";
import PinIcon from "./PinIcon";

export const UserPins: React.FC<{
  items: Pin[];
  onMyProfile: boolean;
  username: string;
}> = ({ items, onMyProfile, username }) => {
  const toast = useToast();
  const deletePin = useDeletePin();
  const deletePinDisclosure = useDisclosure();
  const [pinToDelete, setPinToDelete] = React.useState<Pin | null>(null);
  const [isEditing, setIsEditing] = React.useState(false);

  const allowImages =
    localStorage.getItem("borderRadiusDisabled") !== "true" ||
    !borderRadiusDisabledUsers.includes(username);

  if (!onMyProfile && items.length === 0) {
    return <Box></Box>;
  }
  return (
    <Center width="100%" px={3} pt="1em">
      <PodiumsCard width="100%" p="0.5em 0.75em 0.75em 0.75em">
        <VStack width="100%" alignItems="start">
          <HStack justify="space-between" w="100%">
            <HStack justify="middle" spacing={2}>
              <Icon boxSize="1.5em" as={TiPin}></Icon>
              <Text fontWeight={600}>Pins</Text>
              {onMyProfile && (
                <Tag
                  bg="none"
                  fontWeight={600}
                  color="white"
                  className="bg-party-gradient-button"
                  ml="0.3em"
                >
                  NEW
                </Tag>
              )}
            </HStack>
            {onMyProfile &&
              (isEditing ? (
                <IconButton
                  as={FaCheckCircle}
                  aria-label="Done Editing Pins"
                  onClick={() => setIsEditing(false)}
                  bg="none"
                  size="xs"
                />
              ) : (
                <EditPinOptions onToggleEdit={() => setIsEditing(!isEditing)} />
              ))}
          </HStack>
          <Grid
            templateColumns={`repeat(${Math.min(
              items.length + (onMyProfile ? 1 : 0),
              3
            )}, 1fr)`}
            gap={2}
            width="100%"
          >
            {items.map((item, index) => (
              <GridItem
                key={index}
                pos="relative"
                borderRadius="lg"
                border={"2px solid white"}
                bg="white"
                p="0.5em 0.25em 0.25em 0.25em"
              >
                <VStack width="100%">
                  {!!item.imageURI ? (
                    allowImages ? (
                      <Image
                        src={item.imageURI}
                        alt={item.title}
                        boxSize="2em"
                        borderRadius="full"
                        objectFit="cover"
                      />
                    ) : (
                      <Icon as={FaHeadphones}></Icon>
                    )
                  ) : (
                    <PinIcon slug={item.slug} />
                  )}
                  <Text
                    display="inline-block"
                    textOverflow="elipsis"
                    fontWeight="600"
                    fontSize="0.9em"
                    maxWidth="7.5em"
                    textAlign="center"
                  >
                    {item.title}
                  </Text>
                </VStack>
                {onMyProfile && isEditing && (
                  <IconButton
                    as={FaXmark}
                    aria-label="Remove"
                    pos="absolute"
                    top="5px"
                    right="5px"
                    size="0.75em"
                    bg="none"
                    color="red.400"
                    onClick={() => {
                      setPinToDelete(item);
                      deletePinDisclosure.onOpen();
                    }}
                  />
                )}
              </GridItem>
            ))}
            {items.length < 6 && onMyProfile && <CreatePinGridItem />}
          </Grid>
        </VStack>
        <PodiumsDialog
          title="Remove Pin"
          message={`Are you sure you want to remove "${pinToDelete?.title}" from your pins?`}
          disclosure={deletePinDisclosure}
          buttons={[
            {
              text: "Remove",
              colorScheme: "red",
              onClick: () => {
                toast.promise(deletePin.mutateAsync(pinToDelete?.slug ?? ""), {
                  loading: { title: "Deleting pin..." },
                  success: () => {
                    return {
                      title: "Pin deleted!",
                      status: "info",
                      icon: <Icon as={FaMinusCircle} boxSize="1.5em" />,
                    };
                  },
                  error: { title: "Failed to delete pin" },
                });
              },
            },
          ]}
        />
      </PodiumsCard>
    </Center>
  );
};
const CreatePinGridItem = () => {
  return (
    <GridItem
      key={6}
      pos="relative"
      bg="white"
      borderRadius="4px"
      border="2px dashed black"
      p="0.5em 0.25em 0.25em 0.25em"
      as={Link}
      to="/create/pin"
    >
      <VStack width="100%">
        <Icon as={FaPlus} boxSize="1.5em" />
        <Text
          display="inline-block"
          textOverflow="elipsis"
          fontWeight="600"
          fontSize="0.9em"
          maxWidth="7.5em"
        >
          Add a pin
        </Text>
      </VStack>
    </GridItem>
  );
};
