import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";

const TruncatedTitle: React.FC<{
  bgColor?: string;
  title: string;
  subtitle?: string;
  rank?: number;
  fontWeight?: string | number;
  fontSize?: string;
}> = ({ bgColor, title, subtitle, rank, fontSize, fontWeight }) => {
  return (
    <Box
      overflow="hidden"
      position="relative"
      width="100%"
      textAlign="center"
      _after={{
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        width: "50%",
        height: "100%",
        background: `linear-gradient(to right, rgba(255,255,255,0) 50%,${
          bgColor ?? "white"
        })`,
      }}
    >
      <VStack width="100%" align="left">
        <Text
          display="inline-block"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="elipsis"
          fontWeight={fontWeight ?? "600"}
          fontSize={fontSize ?? "0.6em"}
        >
          {rank && `${rank}. `}
          {title}
        </Text>
        {subtitle && (
          <Text
            display="inline-block"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="elipsis"
            fontWeight="500"
            fontSize="0.4em"
            mt="-1em"
          >
            {subtitle}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default TruncatedTitle;
