import {
  Alert,
  AlertIcon,
  Button,
  Center,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditableTextField from "src/components/EditableTextField";
import { Header } from "src/components/Header/Header";
import PodiumsCard from "src/components/PodiumsCard";
import { UserShimmer } from "src/components/Shimmer";
import { updateMe, verifyEmail } from "src/networking";
import { useCurrentUser } from "src/state/userQueries";
import { useLogout } from "src/utils";

export const VerifyEmail = () => {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [allowResend, setAllowResend] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, isError, isLoading } = useCurrentUser();
  const user = data;
  const [email, setEmail] = useState(user?.email ?? "");

  const params = new URLSearchParams(window.location.search);
  const nonce = params.get("nonce");
  const navigate = useNavigate();
  const toast = useToast();
  const handleLogout = useLogout();

  const sendVerificationEmail = async () => {
    setLoading(true);
    const res = await verifyEmail(undefined);
    setLoading(false);
    if (res === null) {
      setMessage("An error occurred. Please try again later.");
      setSuccess(false);
      return;
    } else if (res.message === "Invalid token") {
      navigate("/login");
      return;
    }
    setMessage(res.message);
    setSuccess(res.success);
  };

  const updateEmail = async () => {
    if (!user || email === user.email) {
      return;
    }
    const userPromise = updateMe({
      email,
    });
    toast.promise(userPromise, {
      success: (newU) => {
        return { title: "Saved!" };
      },
      error: (error: any) => {
        setEmail(user.email || "");
        if (error.response?.status === 400) {
          return { title: "Error", description: error.response.data.message };
        } else {
          // If the request was not successful, handle the error
          if (process.env.REACT_APP_DEBUG) {
            console.error("Error during update email:", error);
          }
          return { title: "Error updating" };
        }
      },
      loading: { title: "Loading..." },
    });
  };

  if (isLoading) {
    return <UserShimmer />;
  }

  if (isError) {
    navigate("/");
    return <>Redirecting...</>;
  }

  return (
    <>
      <Header />
      <Center width="100%" mt={8} minH="80vh" justifyContent="center" px="1em">
        <PodiumsCard>
          <VStack>
            {message && (
              <Alert status={success ? "success" : "error"} borderRadius="md">
                <AlertIcon />
                {message}
              </Alert>
            )}
            {allowResend && (
              <Button
                onClick={() => {
                  setEmailSent(false);
                  setAllowResend(false);
                }}
              >
                Resend verification email
              </Button>
            )}
            {!nonce && !emailSent && (
              <>
                <Text>We'll send a verification email to:</Text>
                <EditableTextField
                  value={email}
                  onChange={setEmail}
                  onSubmit={updateEmail}
                />
                <Button onClick={sendVerificationEmail} isLoading={loading}>
                  Send verification email
                </Button>
              </>
            )}
            <HStack>
              <Text>Made a mistake?</Text>
              <Button onClick={handleLogout}>Logout</Button>
            </HStack>
          </VStack>
        </PodiumsCard>
      </Center>
    </>
  );
};
export default VerifyEmail;
