import {
  Button,
  Heading,
  HStack,
  Icon,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaList, FaUser, FaUsers } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { prettifyPhoneNumber } from "src/components/Auth/verifyPhoneUtils";
import { BackButton } from "src/components/Buttons";
import PodiumsCard, { PodiumsWrap } from "src/components/PodiumsCard";
import { UserRows } from "src/components/User/ListOfUsers";
import {
  useCurrentUser,
  useGetAllUsers,
  useGetWaitlist,
} from "src/state/userQueries";
enum SelectedPage {
  SelectPage = "SelectPage",
  Waitlist = "Waitlist",
  Users = "Users",
}
const AdminPage = () => {
  const [selectedPage, setSelectedPage] = React.useState<SelectedPage>(
    SelectedPage.SelectPage
  );
  const navigate = useNavigate();
  const toast = useToast();

  const currentUserQuery = useCurrentUser();
  const userQuery = useGetAllUsers();
  const waitlistQuery = useGetWaitlist();

  useEffect(() => {
    if (currentUserQuery.data && !currentUserQuery.data.isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You must be an admin to access admin pages.",
        status: "error",
        duration: 9000,
      });
      navigate("/");
    }
  }, [currentUserQuery.data, navigate, toast]);

  const Inner = () => {
    if (currentUserQuery.isLoading) {
      return <Heading>Loading...</Heading>;
    }
    if (selectedPage === SelectedPage.SelectPage) {
      return (
        <VStack w="100%">
          <HStack width="100%">
            <BackButton to="/me" width="auto" />
            <Heading width="100%" textAlign="center">
              Admin
            </Heading>
          </HStack>
          <Button
            onClick={() => setSelectedPage(SelectedPage.Waitlist)}
            width="100%"
            size="lg"
          >
            <Icon as={FaList} mr="0.5em" />
            View Waitlist
          </Button>
          <Button
            onClick={() => setSelectedPage(SelectedPage.Users)}
            width="100%"
            size="lg"
          >
            <Icon as={FaUsers} mr="0.5em" />
            View Users
          </Button>
        </VStack>
      );
    } else if (selectedPage === SelectedPage.Waitlist) {
      return (
        <VStack width="100%">
          <HStack width="100%">
            <BackButton
              onClick={() => setSelectedPage(SelectedPage.SelectPage)}
              width="auto"
            />
            <Heading width="100%" textAlign="center">
              Waitlist ({waitlistQuery.data?.length ?? "..."})
            </Heading>
          </HStack>
          {waitlistQuery.data?.map((user) => (
            <PodiumsCard transition="all 0.15s ease-in-out" py="0.75em">
              <HStack spacing={1.5}>
                <Icon as={FaUser}></Icon>
                <Heading as="h4" size="xs" fontWeight="500">
                  {user.email ??
                    (!!user.phone
                      ? prettifyPhoneNumber(user.phone ?? "")
                      : "error")}
                </Heading>
              </HStack>
            </PodiumsCard>
          ))}
        </VStack>
      );
    } else if (selectedPage === SelectedPage.Users) {
      return (
        <VStack width="100%">
          <HStack width="100%">
            <BackButton
              onClick={() => setSelectedPage(SelectedPage.SelectPage)}
              width="auto"
            />
            <Heading width="100%" textAlign="center">
              Users ({userQuery.data?.length ?? "..."})
            </Heading>
          </HStack>
          <UserRows
            isLoading={userQuery.isLoading}
            isError={userQuery.isError}
            users={userQuery.data}
            navigate={navigate}
          />
        </VStack>
      );
    }
    return null;
  };
  return (
    <PodiumsWrap>
      <Inner />
    </PodiumsWrap>
  );
};

export default AdminPage;
