import {
  Box,
  Circle,
  Fade,
  Heading,
  HStack,
  Icon,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import {
  FaArrowsUpDownLeftRight,
  FaChevronRight,
  FaEyeSlash,
  FaRegGem,
  FaSpotify,
} from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";
import PodiumsCard from "src/components/PodiumsCard";
import {
  BACKDROP_BLUR,
  BACKGROUND_FADED,
  BACKGROUND_HIDDEN,
  borderRadiusDisabledUsers,
} from "src/constants";
import { useCurrentUser } from "src/state/userQueries";
import { UserList } from "src/types/list";
import { SpotifyDialog } from "../Utils/ListDialogs";
import ListGrid from "./ListGrid";

const ListButtton2Col: React.FC<{
  userList: UserList;
  username: string;
  isReorderingLists?: boolean;
  itemLimit?: number;
  itemImageHeight?: any;
  listGridColumns?: number;
  marginBottom?: string;
  tallImages?: boolean;
}> = ({
  userList,
  username,
  isReorderingLists,
  itemLimit,
  itemImageHeight,
  listGridColumns,
  marginBottom,
  tallImages,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: userList._id ?? "" });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const spotifyDisclosure = useDisclosure();
  const limitedDisclosure = useDisclosure();
  const { data: currentUser } = useCurrentUser();

  const isHidden = userList.hidden;
  const showSpotify = userList.integrationProviderSlug === "spotify";
  const isLimited = userList.limitedEdition;

  const background = userList.hidden ? BACKGROUND_HIDDEN : BACKGROUND_FADED;
  return (
    <Box
      width="100%"
      _hover={{ textDecoration: "none" }}
      cursor={isReorderingLists ? "auto" : "cursor"}
      ref={setNodeRef}
      style={style}
      pos="relative"
      mb={marginBottom}
    >
      <PodiumsCard
        align="start"
        p="0.75em"
        bg={background}
        backdropFilter="auto"
        backdropBlur={BACKDROP_BLUR}
        transition="0.3s all"
        as={RouterLink}
        to={isReorderingLists ? undefined : `/${username}/${userList.slug}`}
      >
        <VStack spacing={2} alignItems="start" width="100%" pos="relative">
          <HStack spacing="6px" width="100%">
            {isHidden && <Icon as={FaEyeSlash} boxSize="18px" />}
            {showSpotify && (
              <Icon
                as={FaSpotify}
                boxSize="18px"
                onClick={(e) => {
                  e.preventDefault();
                  spotifyDisclosure.onOpen();
                }}
                cursor="pointer"
              />
            )}
            {isLimited && (
              <Icon
                as={FaRegGem}
                boxSize="16px"
                onClick={(e) => {
                  e.preventDefault();
                  limitedDisclosure.onOpen();
                }}
              />
            )}
            <Box
              overflow="hidden"
              whiteSpace="nowrap"
              style={{
                maskImage:
                  "linear-gradient(to right, black 75%, transparent 100%)",
              }}
              w={`calc(100% - ${
                27 * [isHidden, showSpotify, isLimited].filter(Boolean).length
              }px)`}
            >
              <Heading fontSize="14px">{userList.name}</Heading>
            </Box>
            <Fade
              in={!isReorderingLists}
              style={{ position: "absolute", top: -1, right: -1 }}
              unmountOnExit
            >
              <Icon as={FaChevronRight} boxSize="16px" />
            </Fade>
            <Fade
              in={isReorderingLists}
              style={{
                touchAction: "none",
                position: "absolute",
                top: "2px",
                right: "2px",
              }}
              unmountOnExit
            >
              <Circle
                aria-label="Reorder List"
                bg="rgba(0,0,0,0.7)"
                backdropBlur={BACKDROP_BLUR}
                backdropFilter="auto"
                _active={{ bg: "topFive.black" }}
                color="white"
                cursor="grab"
                mt="-10px"
                mr="-10px"
                py="0.4em"
                px="0.4em"
                zIndex={2}
                {...listeners}
                {...attributes}
              >
                <Icon as={FaArrowsUpDownLeftRight} boxSize="18px"></Icon>
              </Circle>
            </Fade>
          </HStack>
          <ListGrid
            items={userList.items.slice(
              0,
              itemLimit ? Math.min(itemLimit, userList.items.length) : 5
            )}
            imageHeight={itemImageHeight}
            bgColor={userList.hidden ? "topFive.gray.light" : "white"}
            borderRadius={
              localStorage.getItem("borderRadiusDisabled") !== "true" ||
              borderRadiusDisabledUsers.includes(username)
            }
            columns={listGridColumns}
            tallImages={tallImages}
          />
        </VStack>
      </PodiumsCard>
      {false && (
        <Box
          pos="absolute"
          right="-8px"
          opacity={isReorderingLists ? 1 : 0}
          top="-8px"
          style={{ touchAction: "none" }}
          transition="0.3s all"
        >
          <Fade style={{ touchAction: "none" }} in={isReorderingLists}>
            <Circle
              aria-label="Reorder List"
              bg="rgba(0,0,0,0.7)"
              backdropBlur={BACKDROP_BLUR}
              backdropFilter="auto"
              _active={{ bg: "topFive.black" }}
              color="white"
              mb="1em"
              cursor="grab"
              py="0.4em"
              px="0.4em"
              zIndex={2}
              {...listeners}
              {...attributes}
            >
              <Icon
                as={FaArrowsUpDownLeftRight}
                maxW="1em"
                fontSize="1.25em"
              ></Icon>
            </Circle>
          </Fade>
        </Box>
      )}
      <SpotifyDialog
        listSlug={userList.slug}
        disclosure={spotifyDisclosure}
        isUpdatingAutomatically={userList.updateAutomatically ?? false}
        onMyProfile={currentUser?.username === username}
      />
    </Box>
  );
};

export default ListButtton2Col;
