import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaMinusCircle } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import { SearchField } from "src/components/Lists/Utils/Search";
import PodiumsCard, { PodiumsWrap } from "src/components/PodiumsCard";
import { ShimmerListOptions } from "src/components/Shimmer";
import GradientBackground from "src/components/User/UserBackground";
import { useGetPinOptions } from "src/state/pinQueries";
import {
  useCreatePin,
  useCurrentUser,
  useDeletePin,
} from "src/state/userQueries";
import { listNameToSlug } from "src/utils";
import { BackButton } from "../components/Buttons";
import PinIcon from "../components/Pins/PinIcon";
import { Pin, PinOption } from "../types/pin";

export const CreatePin = () => {
  const [selectedPinOption, setSelectedPin] = useState<PinOption | null>(null);

  const userQuery = useCurrentUser();
  const pinOptionsQuery = useGetPinOptions();
  const createPin = useCreatePin();
  const deletePin = useDeletePin();
  const toast = useToast();
  const navigate = useNavigate();

  const addPin = (title: string, slug: string, imageURI?: string) => {
    toast.promise(
      createPin.mutateAsync({
        slug,
        title,
        imageURI,
      }),
      {
        loading: { title: "Creating pin..." },
        success: () => {
          navigate("/me");
          return { title: "Pin created!" };
        },
        error: { title: "Failed to create pin" },
      }
    );
  };

  if (pinOptionsQuery.isLoading || userQuery.isLoading) {
    return (
      <PodiumsWrap>
        <VStack width="100%" spacing={3}>
          <Heading textAlign="center" fontSize="30px">
            Add a pin
          </Heading>
          <ShimmerListOptions />
        </VStack>
      </PodiumsWrap>
    );
  }

  if (
    !pinOptionsQuery.data ||
    pinOptionsQuery.isError ||
    !userQuery.data ||
    userQuery.isError
  ) {
    return (
      <PodiumsWrap>
        <VStack width="100%" spacing={3}>
          <Heading textAlign="center" fontSize="30px">
            Error
          </Heading>
        </VStack>
      </PodiumsWrap>
    );
  }

  if ((userQuery.data.pins?.length ?? 0) >= 6) {
    return (
      <PodiumsWrap>
        <VStack width="100%" spacing={3}>
          <Heading textAlign="center" fontSize="30px">
            Oops...
          </Heading>
          <Text>You've reached the maximum number of pins</Text>
        </VStack>
      </PodiumsWrap>
    );
  }

  const pins = pinOptionsQuery.data;

  if (selectedPinOption === null) {
    // First screen -
    return (
      <GradientBackground>
        <Header />
        <Center width="100%">
          <PodiumsCard p="2em" mx="1em" mb="1em">
            <VStack width="100%" spacing={3}>
              <HStack
                width="100%"
                position="relative"
                alignContent="center"
                justifyContent="center"
              >
                <Box position="absolute" left="0">
                  <BackButton to="/me" />
                </Box>
                <Heading textAlign="center" fontSize="30px">
                  Add a pin
                </Heading>
              </HStack>
              <Heading
                textAlign="center"
                fontSize="md"
                fontWeight={500}
                mb="0.5em"
              >
                You can show up to 6 on your profile.
              </Heading>
              <Box width="100%">
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  {Object.keys(pins).map((pinSlug) => {
                    const pin = pins[pinSlug];
                    return (
                      <GridItem
                        key={pinSlug}
                        borderRadius="lg"
                        onClick={() => {
                          if (!!pin.options || !!pin.listSlug) {
                            // Pin has options - it's not a direct pin (leaf node)
                            setSelectedPin(pin);
                          } else {
                            addPin(pin.name, pin.slug, pin.imageURI);
                          }
                        }}
                        p="0.75em"
                        cursor="pointer"
                        bg="gray.100"
                      >
                        <VStack justifyContent="center">
                          <PinIcon slug={pin.slug} />
                          <HStack spacing={1}>
                            <Heading
                              fontSize="0.9em"
                              textAlign="center"
                              lineHeight="1.3em"
                            >
                              {pin.name}
                            </Heading>
                            {(pin.options || pin.listSlug) && (
                              <Icon as={FaChevronRight} boxSize="0.9em" />
                            )}
                          </HStack>
                        </VStack>
                      </GridItem>
                    );
                  })}
                </Grid>
              </Box>
            </VStack>
          </PodiumsCard>
        </Center>
      </GradientBackground>
    );
  } else {
    return (
      <Box className="bg-party-gradient" minH="100vh" width="100%">
        <Header />
        <Center minH="80vh" width="100%">
          <PodiumsCard py="2em" m="1em">
            <VStack width="100%" spacing={3}>
              <HStack justifyContent="center" mb="4px" spacing={3}>
                <Box position="absolute" left="1.5em">
                  <BackButton onClick={() => setSelectedPin(null)} />
                </Box>
                <PinIcon slug={selectedPinOption.slug} />
                <Heading>{selectedPinOption.name}</Heading>
              </HStack>

              {!!selectedPinOption.options && (
                <Grid templateColumns={`repeat(2, 1fr)`} gap={2} width="100%">
                  {selectedPinOption.options.map((o) => {
                    const pinAlreadyExists = (
                      userQuery.data?.pins?.map((p) => p.slug) ?? []
                    ).includes(o.slug);
                    return (
                      <GridItem
                        key={o.slug}
                        borderRadius="lg"
                        p="0.75em"
                        bg={pinAlreadyExists ? "green.200" : "gray.100"}
                        cursor="pointer"
                        width="100%"
                        onClick={() => {
                          if (pinAlreadyExists) {
                            navigate("/me/");
                            return;
                          }
                          addPin(o.title, o.slug, o.imageURI);
                        }}
                      >
                        <VStack width="100%">
                          <PinIcon slug={o.slug} imageURI={o.imageURI} />
                          <Heading
                            fontSize="0.9em"
                            textAlign="center"
                            lineHeight="1.3em"
                          >
                            {o.title}
                          </Heading>
                        </VStack>
                      </GridItem>
                    );
                  })}
                </Grid>
              )}
              {!!selectedPinOption.listSlug && (
                <SearchField
                  handleAddItem={(o) => {
                    const i = { ...o, slug: listNameToSlug(o.title) } as Pin;
                    addPin(i.title, i.slug, i.imageURI);
                  }}
                  handleRemoveItem={(item) => {
                    const pinToDelete = {
                      ...item,
                      slug: listNameToSlug(item.title),
                    } as Pin;
                    toast.promise(
                      deletePin.mutateAsync(pinToDelete?.slug ?? ""),
                      {
                        loading: { title: "Deleting pin..." },
                        success: () => {
                          return {
                            title: "Pin deleted!",
                            status: "info",
                            icon: <Icon as={FaMinusCircle} boxSize="1.5em" />,
                          };
                        },
                        error: { title: "Failed to delete pin" },
                      }
                    );
                  }}
                  listName={selectedPinOption.name}
                  modalStartsOpen
                  // existingItemIdentifiers={new Set()}
                />
              )}
            </VStack>
          </PodiumsCard>
        </Center>
      </Box>
    );
  }
};
