import {
  Alert,
  AlertIcon,
  Button,
  Center,
  FormControl,
  Heading,
  Input,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { phoneRegex } from "src/constants";
import { checkUser } from "src/networking";
import { useWaitlist } from "src/state/userQueries";
import { errorToast } from "src/utils";
import { AppIconLogo } from "../Header/Header";
import VerifyPhone from "./VerifyPhoneInput";
import { minifyPhoneNumber } from "./verifyPhoneUtils";

const WaitlistForm = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alreadyOnWaitlist, setAlreadyOnWaitlist] = useState(false);

  const toast = useToast();
  const signUpWaitlist = useWaitlist();

  const handleSignup = async (firebaseToken?: string) => {
    if (loading) return;
    setLoading(true);
    let signUpPromise;
    if (firebaseToken) {
      signUpPromise = signUpWaitlist.mutateAsync({
        phone,
        firebaseToken,
      });
    } else if (email) {
      signUpPromise = signUpWaitlist.mutateAsync({
        email,
      });
    } else {
      errorToast("An error occurred, please try again.", toast);
      setLoading(false);
      return;
    }
    signUpPromise
      .then((_user) => {
        setLoading(false);
        setStepNumber(2);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          errorToast(error.response.data.message, toast);
        } else {
          // If the request was not successful, handle the error
          if (process.env.REACT_APP_DEBUG) {
            console.error("Error during authentication:", error);
          }
          errorToast("Error authenticating", toast);
        }
      });
  };

  if (stepNumber === 0) {
    return (
      <Center minH="85vh">
        <VStack spacing={4} w="100%">
          <AppIconLogo />
          <Heading textAlign="center" mb={2} color="topFive.primary">
            Join Waitlist
          </Heading>
          <Heading
            textAlign="center"
            mb={2}
            color="topFive.primary"
            as="h3"
            fontSize="1.25em"
            fontWeight={500}
          >
            We'll let you know when we launch.
          </Heading>
          <Tabs isFitted position="relative" width="100%">
            <TabList>
              <Tab fontWeight="600">Email</Tab>
              <Tab fontWeight="600">Phone</Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />
            <TabPanels pt="0.5em">
              <TabPanel>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const res = await checkUser(email);
                    if (res.success) {
                      errorToast(
                        "An account with that email already exists",
                        toast
                      );
                    } else {
                      handleSignup();
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  <VStack spacing={4}>
                    <FormControl>
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                        autoCapitalize="none"
                        spellCheck="false"
                        enterKeyHint="go"
                      />
                    </FormControl>
                    <Button
                      w="100%"
                      variant="dark"
                      type="submit"
                      className="bg-party-gradient-button"
                      borderRadius="full"
                    >
                      Continue
                    </Button>
                  </VStack>
                </form>
              </TabPanel>
              <TabPanel>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (!phoneRegex.test(minifyPhoneNumber(phone))) {
                      errorToast("Invalid phone number", toast);
                    } else if (
                      (await checkUser(minifyPhoneNumber(phone))).success
                    ) {
                      setAlreadyOnWaitlist(true);
                      setStepNumber(2);
                    } else {
                      setStepNumber(1);
                    }
                  }}
                  style={{ width: "100%" }}
                >
                  <VStack spacing={4}>
                    <FormControl>
                      <Input
                        type="phone"
                        value={phone}
                        placeholder="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        enterKeyHint="next"
                      />
                    </FormControl>

                    <Button
                      w="100%"
                      variant="dark"
                      type="submit"
                      className="bg-party-gradient-button"
                      borderRadius="full"
                    >
                      Continue
                    </Button>
                  </VStack>
                </form>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </Center>
    );
  } else if (stepNumber === 1) {
    // phone
    return (
      <VerifyPhone
        phoneNumber={minifyPhoneNumber(phone)}
        setFirebaseToken={(v) => {
          console.log("About to handle signup. code:", v);
          handleSignup(v);
        }}
        onBack={() => {
          setStepNumber(0);
        }}
      />
    );
  } else if (stepNumber === 2) {
    return (
      <Alert status="success" borderRadius="md">
        <AlertIcon />
        You're {alreadyOnWaitlist ? "already" : "now"} on the waitlist! We'll
        let you know when Podiums is ready.
      </Alert>
    );
  } else {
    throw new Error("Unimplemented step number");
  }
};

export default WaitlistForm;
