import { Box, Center, Heading, Spinner, VStack } from "@chakra-ui/react";
import { OAuthPopup } from "@tasoskakour/react-use-oauth2";
import React from "react";
import PodiumsCard from "src/components/PodiumsCard";
import GradientBackground from "src/components/User/UserBackground";

const CallbackPage = () => {
  return (
    <GradientBackground>
      <Center px="1em" minHeight="75vh">
        <PodiumsCard>
          <Center minHeight="50vh">
            <VStack>
              <Heading>Loading...</Heading>
              <Spinner size="xl" />
            </VStack>
          </Center>
        </PodiumsCard>
      </Center>
      <Box opacity={0}>
        <OAuthPopup />
      </Box>
    </GradientBackground>
  );
};

export default CallbackPage;
