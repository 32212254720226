import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import {
  FaEye,
  FaEyeSlash,
  FaList,
  FaPencil,
  FaTableCellsLarge,
  FaTrash,
} from "react-icons/fa6";

export const EditListOptions: React.FC<{
  hidden: boolean;
  onToggleHidden: () => void;
  onToggleEdit: () => void;
  onDelete: () => void;
  onToggleGrid: () => void;
  isGrid: boolean;
}> = ({
  hidden,
  onToggleHidden,
  onDelete,
  onToggleEdit,
  onToggleGrid,
  isGrid,
}) => {
  return (
    <Menu>
      <MenuButton
        aria-label="Options"
        color="black"
        fontWeight="800"
        borderRadius="full"
      >
        •••
      </MenuButton>
      <MenuList boxShadow="0px 8px 20px 0px rgba(0,0,0,0.2)">
        <MenuItem icon={<FaPencil />} onClick={onToggleEdit}>
          Edit list
        </MenuItem>
        <MenuItem
          icon={isGrid ? <FaList /> : <FaTableCellsLarge />}
          onClick={onToggleGrid}
        >
          View as {isGrid ? "list" : "grid"}
        </MenuItem>
        {/* {onToggleRefresh && (
          <MenuItem icon={<FaSpotify />} onClick={onToggleRefresh}>
            Refresh from Spotify
          </MenuItem>
        )} */}
        <MenuItem
          icon={hidden ? <FaEye /> : <FaEyeSlash />}
          onClick={onToggleHidden}
        >
          {hidden ? "Show list on profile" : "Hide list on profile"}
        </MenuItem>
        <MenuItem icon={<FaTrash />} color="red.500" onClick={onDelete}>
          Delete list
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
