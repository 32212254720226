import { Box, Image } from "@chakra-ui/react";
import React from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, EffectCards } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ScreenshotSlider = () => {
  return (
    <Box maxW={300}>
      <Swiper
        modules={[Autoplay, EffectCards]}
        speed={500}
        effect="cards"
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        cardsEffect={{ slideShadows: false }}
        slidesPerView={1}
        width={300}
        style={{ overflow: "visible" }}
      >
        <SwiperSlide>
          <Image
            src="/profile-screenshot.png"
            width="100%"
            height="auto"
            objectFit="cover"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            src="/list-screenshot.png"
            maxW="400px"
            width="100%"
            height="auto"
            objectFit="cover"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Image
            src="/link-screenshot.png"
            maxW="400px"
            width="100%"
            height="auto"
            objectFit="cover"
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default ScreenshotSlider;
