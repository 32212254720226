import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import React, { ReactNode, useRef } from "react";
import { IconType } from "react-icons";

interface ButtonType {
  onClick: () => void;
  text: string;
  icon?: IconType;
  colorScheme?: string;
}

export const PodiumsDialog: React.FC<{
  disclosure: { isOpen: boolean; onOpen: () => void; onClose: () => void };
  title: string;
  titleIcon?: IconType;
  message?: string;
  content?: ReactNode;
  danger?: boolean;
  buttons?: ButtonType[];
  hideOk?: boolean;
  cancelText?: string;
}> = ({
  disclosure: { isOpen, onClose },
  title,
  titleIcon,
  message,
  content,
  cancelText,
  buttons,
  hideOk,
}) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent m="0.5em" borderRadius="xl">
          <AlertDialogHeader fontSize="xl" fontWeight="bold">
            <HStack>
              {titleIcon && <Icon as={titleIcon} boxSize="1.25em" />}
              <Text>{title}</Text>
            </HStack>
          </AlertDialogHeader>

          <AlertDialogBody>
            {message}
            {content}
          </AlertDialogBody>

          <AlertDialogFooter>
            {cancelText && (
              <Button ref={cancelRef} onClick={onClose} px="2em">
                {cancelText}
              </Button>
            )}
            {buttons &&
              buttons.map((button, i) => (
                <Button
                  key={i}
                  colorScheme={button.colorScheme ?? "blue"}
                  onClick={() => {
                    button.onClick();
                    onClose();
                  }}
                  ml={3}
                  px="2em"
                >
                  {button.icon && (
                    <Icon as={button.icon} mr={2} fontSize="1.25em" />
                  )}
                  {button.text}{" "}
                </Button>
              ))}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
