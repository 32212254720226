import { HStack, Heading, IconButton, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FaXmark } from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";
import { SimpleUser } from "src/types/user";
import PodiumsCard from "../PodiumsCard";
import { ProfilePicture } from "./UserProfileHeader";

const UserSearchRow: React.FC<{
  user: SimpleUser;
  onClick?: (u: string) => void;
  onRemove?: (u: string) => void;
  isRecent?: boolean;
}> = ({ user, onClick, onRemove, isRecent }) => {
  const numLists = user.lists?.length;
  const numListText = numLists === 1 ? "1 list" : `${numLists} lists`;

  return (
    <PodiumsCard transition="all 0.15s ease-in-out" py="0.75em">
      <HStack align="center" width="100%" justify="space-between">
        <HStack
          spacing={4}
          onClick={(e: any) => {
            e.preventDefault();
            onClick && onClick(user.username);
          }}
          width="100%"
          as={RouterLink}
          to={`/${user.username}`}
        >
          <ProfilePicture src={user.profilePicture} size={2.5} />
          <VStack align="left" spacing={0}>
            <Heading size="sm" marginBottom={0}>
              {user.firstName + " " + user.lastName}
            </Heading>
            <HStack spacing={1.5}>
              <Heading as="h4" size="xs" fontWeight="500">
                @{user.username}
              </Heading>
              {user.lists !== undefined && (
                <>
                  <Text>•</Text>
                  <Text size="xs" fontWeight="500">
                    {numListText}
                  </Text>
                </>
              )}
            </HStack>
          </VStack>
        </HStack>

        {isRecent && (
          <IconButton
            size="xs"
            bg="none"
            aria-label={isRecent ? "Remove recent search" : "View profile"}
            onClick={() => onRemove && onRemove(user.username)}
            as={FaXmark}
          />
        )}
      </HStack>
    </PodiumsCard>
  );
};
export default UserSearchRow;
