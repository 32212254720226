import { Icon, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { FaArrowUpRightFromSquare, FaSpotify } from "react-icons/fa6";

const ItemMenuButton: React.FC<{ children: ReactNode; link?: string }> = ({
  children,
  link,
}) => {
  return link ? (
    <Menu>
      <MenuButton width="100%">{children}</MenuButton>
      <MenuList mt="-1.25em">
        <MenuItem icon={<FaSpotify />} as="a" href={link} target="_blank">
          View on Spotify
          <Icon as={FaArrowUpRightFromSquare} boxSize="0.9em" ml="10px"></Icon>
        </MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <>{children}</>
  );
};

export default ItemMenuButton;
