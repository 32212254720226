import {
  Center,
  Heading,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { FaCircleXmark } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import Onboarding from "src/components/Lists/Edit/Onboarding";
import ListOfLists from "src/components/Lists/View/ListOfLists";
import TwoColumnListOfLists from "src/components/Lists/View/TwoColumnListOfLists";
import { UserShimmer } from "src/components/Shimmer";
import { FloatingCreateButton } from "src/components/User/FloatingCreateButton";
import GradientBackground from "src/components/User/UserBackground";
import { UserProfileHeader } from "src/components/User/UserProfileHeader";
import {
  CURRENT_USER_QUERY,
  useCurrentUser,
  useDisconnectSpotify,
  useGetUser,
  useReorderLists,
  useUpdateMe,
} from "src/state/userQueries";
import { UserList } from "src/types/list";
import "../backgrounds.css";
import { UserPins } from "../components/Pins/Pins";
import PodiumsCard from "../components/PodiumsCard";
import { PodiumsDialog } from "../components/PodiumsDialog";

const UserPage: React.FC = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const reorderListsDisclosure = useDisclosure();
  const disconnectSpotifyDisclosure = useDisclosure();
  const toast = useToast();

  const pinsEnabled = useFeatureFlagEnabled("pinsEnabled");

  const {
    data: currentUser,
    isLoading: curUserLoading,
    isError: curUserError,
  } = useCurrentUser();
  const onMyProfile =
    curUserLoading || (!!currentUser && currentUser.username === username);
  const {
    data: getUserData,
    isError: getUserError,
    isLoading: getUserLoading,
  } = useGetUser(username ?? "", !onMyProfile);

  const reorderLists = useReorderLists();
  const disconnectSpotify = useDisconnectSpotify();
  const [lists, setLists] = useState<UserList[]>([]);
  const [listsLoading, setListsLoading] = useState(true);
  const [leftColLists, setLeftColLists] = useState<UserList[]>([]);
  const [rightColLists, setRightColLists] = useState<UserList[]>([]);
  const [isSingleCol, setIsSingleCol] = useState(true);
  const location = useLocation();
  const updateMe = useUpdateMe();
  const backURL = location.state?.backURL;
  const queryClient = useQueryClient();

  const user = onMyProfile ? currentUser! : getUserData;
  const [theme, setTheme] = useState<string | undefined>(user?.backgroundType);

  const onDisconnectSpotify = () => {
    toast.promise(disconnectSpotify.mutateAsync(), {
      success: { title: "Spotify disconnected" },
      loading: { title: "Loading..." },
      error(err) {
        return {
          title: "Error",
          description: err.message,
        };
      },
    });
    navigate("/me");
  };

  useEffect(() => {
    if (username !== username?.toLowerCase()) {
      navigate(`/${username?.toLowerCase()}`);
      return;
    }
    if (curUserError || getUserError) {
      setListsLoading(false);
    }

    document.title = "Podiums" + (!!user && `— @${user.username}`);
  }, [username, user, navigate, curUserError, getUserError]);

  useEffect(() => {
    if (!!user) {
      setTheme(user.backgroundType);
      setIsSingleCol(user.listLayout.length === 1);
      if (user.listLayout.length === 1) {
        // set lists and sort by listLayout array containing _ids
        setLists(
          (user.lists ?? []).sort((a, b) => {
            const aIndex = user.listLayout[0].indexOf(a._id ?? "");
            const bIndex = user.listLayout[0].indexOf(b._id ?? "");
            return aIndex - bIndex;
          })
        );
        setLeftColLists(
          (user.lists ?? []).slice(0, Math.ceil(user.lists?.length / 2))
        );
        setRightColLists(
          (user.lists ?? []).slice(Math.ceil(user.lists?.length / 2))
        );
      } else {
        setLists(user.lists ?? []);
        const leftCol = user.lists
          .filter((l) => user.listLayout[0].includes(l._id ?? ""))
          .sort((a, b) => {
            const aIndex = user.listLayout[0].indexOf(a._id ?? "");
            const bIndex = user.listLayout[0].indexOf(b._id ?? "");
            return aIndex - bIndex;
          });
        // Ensure all other lists are in the right column
        const rightCol = user.lists
          .filter((l) => leftCol.map((l) => l._id).indexOf(l._id) === -1)
          .sort((a, b) => {
            const aIndex = user.listLayout[1].indexOf(a._id ?? "");
            const bIndex = user.listLayout[1].indexOf(b._id ?? "");
            return aIndex - bIndex;
          });
        setLeftColLists(leftCol);
        setRightColLists(rightCol);
      }
      setListsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, onMyProfile]);

  const onSaveReorderLists = () => {
    if (!user || !lists) {
      return;
    }
    reorderListsDisclosure.onClose();
    if (isSingleCol) {
      saveListsSingleColumn();
    } else {
      saveLists2Column();
    }
  };

  const onSaveTheme = () => {
    if (
      theme !== user?.backgroundType ||
      isSingleCol !== (user?.listLayout.length === 1)
    ) {
      toast.promise(
        updateMe.mutateAsync({
          backgroundType: theme,
          listLayoutColumns: isSingleCol ? 1 : 2,
        }),
        {
          success: { title: "Theme saved" },
          loading: { title: "Loading..." },
          error(err) {
            return {
              title: "Error",
              description: err.message,
            };
          },
        }
      );
      queryClient.invalidateQueries({ queryKey: CURRENT_USER_QUERY });
      navigate(`/me`);
    }
  };

  if (getUserLoading || curUserLoading || listsLoading) {
    return <UserShimmer />;
  } else if (getUserError) {
    return (
      <>
        <GradientBackground faded>
          <Header />
          <Center width="100%" px={3}>
            <PodiumsCard>
              <Heading size="4xl">😥</Heading>
              <Heading>User not found</Heading>
            </PodiumsCard>
          </Center>
        </GradientBackground>
      </>
    );
  } else {
    const hasSpotify =
      !!onMyProfile && currentUser
        ? currentUser.oauthIntegrations.find(
            (i) => i.providerSlug === "spotify"
          )
        : false;
    return (
      <>
        <GradientBackground theme={theme}>
          <Header />
          <UserProfileHeader
            user={user}
            isLoggedIn={!!currentUser}
            onMyProfile={onMyProfile}
            onReorderLists={reorderListsDisclosure.onOpen}
            isReorderingLists={reorderListsDisclosure.isOpen}
            onSaveLists={onSaveReorderLists}
            onDisconnectSpotify={
              hasSpotify ? disconnectSpotifyDisclosure.onOpen : undefined
            }
            updateThemeLocal={setTheme}
            updateLayoutLocal={setIsSingleCol}
            onSaveTheme={onSaveTheme}
            backURL={backURL}
          />
          {pinsEnabled && (
            <UserPins
              items={user?.pins ?? []}
              onMyProfile={onMyProfile}
              username={username ?? ""}
            />
          )}
          <Center width="100%" position="relative" pt={3} pb="2em">
            <VStack position="relative" spacing={4} width="100%">
              {lists.length > 0 ? (
                <Center width="100%" px={3}>
                  {isSingleCol ? (
                    <ListOfLists
                      username={user?.username ?? ""}
                      lists={lists}
                      setLists={setLists}
                      isReorderingLists={reorderListsDisclosure.isOpen}
                    />
                  ) : (
                    <TwoColumnListOfLists
                      username={user?.username ?? ""}
                      leftCol={leftColLists}
                      rightCol={rightColLists}
                      setLeftCol={setLeftColLists}
                      setRightCol={setRightColLists}
                      isReorderingLists={reorderListsDisclosure.isOpen}
                    />
                  )}
                </Center>
              ) : onMyProfile ? null : (
                <Center width="100%" px={3}>
                  <PodiumsCard>
                    <Heading size="4xl">😥</Heading>
                    <Heading>{user?.username} has no lists!</Heading>
                  </PodiumsCard>
                </Center>
              )}
              {onMyProfile && (
                <>
                  {lists.length === 0 && <Onboarding />}
                  <FloatingCreateButton isFirstList={lists.length === 0} />
                </>
              )}
            </VStack>
          </Center>
        </GradientBackground>
        <PodiumsDialog
          disclosure={disconnectSpotifyDisclosure}
          title="Disconnect Spotify"
          message="Are you sure you want to disconnect Spotify? This will remove your recommendations on Podiums, and stop auto-syncing any connected lists."
          buttons={[
            {
              text: "Disconnect",
              onClick: onDisconnectSpotify,
              colorScheme: "red",
              icon: FaCircleXmark,
            },
          ]}
          cancelText="Cancel"
        />
      </>
    );
  }
  function saveLists2Column() {
    toast.promise(
      reorderLists.mutateAsync({
        leftColListIDs: leftColLists.map((l) => l._id ?? ""),
        rightColListIDs: rightColLists.map((l) => l._id ?? ""),
      }),
      {
        success: { title: "Lists saved!" },
        loading: { title: "Loading..." },
        error(err) {
          return {
            title: "Error",
            description: err.message,
          };
        },
      }
    );
  }
  function saveListsSingleColumn() {
    toast.promise(
      reorderLists.mutateAsync({
        singleColListIDs: lists?.map((l) => l._id ?? ""),
      }),
      {
        success: { title: "Lists saved!" },
        loading: { title: "Loading..." },
        error(err) {
          return {
            title: "Error",
            description: err.message,
          };
        },
      }
    );
  }
};

export default UserPage;
