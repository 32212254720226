import { Box, Flex } from "@chakra-ui/react";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { UserList } from "src/types/list";

import { TALL_IMAGE_LISTS } from "src/constants";
import ListButtton2Col from "./ListButton";

const TwoColumnListOfLists: React.FC<{
  leftCol: UserList[];
  rightCol: UserList[];
  username: string;
  setLeftCol: (l: UserList[]) => void;
  setRightCol: (l: UserList[]) => void;
  isReorderingLists?: boolean;
}> = ({
  leftCol,
  rightCol,
  username,
  setLeftCol,
  setRightCol,
  isReorderingLists,
}) => {
  const [items, setItems] = useState<{ [key: string]: UserList[] }>({
    colA: leftCol,
    colB: rightCol,
  });

  const [activeId, setActiveId] = useState<string | null>(null);

  useEffect(() => {
    setLeftCol(items.colA);
    setRightCol(items.colB);
  }, [items, setLeftCol, setRightCol]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: any) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragOver = (event: any) => {
    const { active, over } = event;
    if (!over) return;

    const activeColumn = Object.keys(items).find((key) =>
      items[key].find((item) => item._id === active.id)
    );
    const overColumn =
      over.id === "colA" || over.id === "colB"
        ? over.id
        : Object.keys(items).find((key) =>
            items[key].find((item) => item._id === over.id)
          );

    if (!activeColumn || !overColumn || activeColumn === overColumn) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeColumn];
      const overItems = prev[overColumn];

      const activeIndex = activeItems.findIndex(
        (item) => item._id === active.id
      );
      const overIndex =
        over.id === "colA" || over.id === "colB"
          ? overItems.length // If dragging to an empty column, add to the end
          : overItems.findIndex((item) => item._id === over.id);

      return {
        ...prev,
        [activeColumn]: [
          ...prev[activeColumn].filter((item) => item._id !== active.id),
        ],
        [overColumn]: [
          ...prev[overColumn].slice(0, overIndex),
          activeItems[activeIndex],
          ...prev[overColumn].slice(overIndex),
        ],
      };
    });
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!over) {
      setActiveId(null);
      return;
    }

    if (active.id !== over.id) {
      const activeColumn = Object.keys(items).find((key) =>
        items[key].find((item) => item._id === active.id)
      );
      const overColumn =
        over.id === "colA" || over.id === "colB"
          ? over.id
          : Object.keys(items).find((key) =>
              items[key].find((item) => item._id === over.id)
            );

      if (activeColumn && overColumn) {
        setItems((prev) => {
          const activeItems = prev[activeColumn];
          const overItems = prev[overColumn];

          const activeIndex = activeItems.findIndex(
            (item) => item._id === active.id
          );
          const overIndex =
            over.id === "colA" || over.id === "colB"
              ? overItems.length // If dragging to an empty column, add to the end
              : overItems.findIndex((item) => item._id === over.id);

          if (activeColumn !== overColumn) {
            return {
              ...prev,
              [activeColumn]: [
                ...prev[activeColumn].filter((item) => item._id !== active.id),
              ],
              [overColumn]: [
                ...prev[overColumn].slice(0, overIndex),
                { ...activeItems[activeIndex] },
                ...prev[overColumn].slice(overIndex),
              ],
            };
          } else {
            return {
              ...prev,
              [overColumn]: arrayMove(overItems, activeIndex, overIndex),
            };
          }
        });
      }
    }

    setActiveId(null);
  };

  const Column: React.FC<{ columnKey: string }> = ({ columnKey }) => {
    const { setNodeRef } = useDroppable({ id: columnKey });
    return (
      <Box ref={setNodeRef} flex={1} maxW="calc(50% - 0.5em)">
        <SortableContext
          items={items[columnKey].map((item) => item._id ?? "")}
          strategy={verticalListSortingStrategy}
        >
          {items[columnKey].map((item) => {
            const tallImages = TALL_IMAGE_LISTS.includes(item.slug);
            return (
              <ListButtton2Col
                key={item._id}
                userList={item}
                username={username}
                isReorderingLists={isReorderingLists}
                marginBottom="10px"
                itemLimit={4}
                listGridColumns={2}
                itemImageHeight={
                  tallImages
                    ? { base: "6.25em", sm: "8.5em" }
                    : { base: "4.5em", sm: "6em" }
                }
              />
            );
          })}
        </SortableContext>
      </Box>
    );
  };

  const activeItem =
    activeId &&
    (items.colA.find((item) => item._id === activeId) ||
      items.colB.find((item) => item._id === activeId));

  return (
    <Box width="100%" maxW="450px">
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <Flex gap={3}>
          <Column columnKey="colA" />
          <Column columnKey="colB" />
        </Flex>
        <DragOverlay>
          {activeItem && (
            <ListButtton2Col
              userList={activeItem}
              username={username}
              isReorderingLists={isReorderingLists}
              itemImageHeight={
                TALL_IMAGE_LISTS.includes(activeItem.slug)
                  ? { base: "2.3em", sm: "3em" }
                  : { base: "1.5em", sm: "2.5em" }
              }
            />
          )}
        </DragOverlay>
      </DndContext>
    </Box>
  );
};

export default TwoColumnListOfLists;
