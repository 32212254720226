import React, { ReactNode } from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, EffectCards, Pagination, Parallax } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface HorizontalSwiperProps {
  slides: ReactNode[];
  pagination?: boolean;
  [key: string]: any;
}

const HorizontalSwiper: React.FC<HorizontalSwiperProps> = ({
  slides,
  pagination,
  ...rest
}) => {
  const pgOptions: any = {
    clickable: true,
    dynamicBullets: true,
  };
  const modules = [Parallax, Autoplay, EffectCards];
  return (
    <Swiper
      pagination={pgOptions}
      modules={pagination ? [...modules, Pagination] : modules}
      // parallax={true}
      speed={500}
      spaceBetween={0}
      slidesPerView={1}
      {...rest}
      // TODO: Add breakpoints so desktop has 3 slides
      // breakpoints={{
      //   768: { slidesPerView: 3 },
      // }}
    >
      {React.Children.map(slides, (child) => (
        <SwiperSlide>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HorizontalSwiper;
