import {
  Box,
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaSpinner } from "react-icons/fa6";
import { ShimmerThumbnail as Thumbnail } from "react-shimmer-effects";
import { Header } from "./Header/Header";
import PodiumsCard from "./PodiumsCard";
import GradientBackground from "./User/UserBackground";

export const ShimmerThumbnail: React.FC<{
  short?: boolean;
  [key: string]: any;
}> = ({ short, ...rest }) => {
  return (
    <Box
      boxSize={short ? "3em" : "5em"}
      overflow="hidden"
      borderRadius="sm"
      {...rest}
    >
      <Thumbnail height={300} width={300} />
    </Box>
  );
};

export const ShimmerCircleThumbnail: React.FC<{ boxSize: string }> = ({
  boxSize,
}) => {
  return (
    <Box boxSize={boxSize} overflow="hidden" borderRadius="full">
      <Thumbnail height={300} width={300} />
    </Box>
  );
};

export const ShimmerCategoryItem: React.FC = () => {
  return (
    <HStack width="100%">
      <ShimmerThumbnail />
      <VStack align="left" width="70%">
        <Box overflow="hidden" height="1.25em" borderRadius="md" width="8em">
          <Thumbnail width={300} height={200} />
        </Box>
        <Box overflow="hidden" height="0.5em" borderRadius="sm" width="100%">
          <Thumbnail width={300} height={150} />
        </Box>
        <Box overflow="hidden" height="0.5em" borderRadius="sm" width="100%">
          <Thumbnail width={300} height={150} />
        </Box>
      </VStack>
    </HStack>
  );
};

export const ShimmerUserProfileHeader: React.FC = () => {
  return (
    <Center width="100%" px={3} pb="0.5em">
      <PodiumsCard>
        <HStack width="100%">
          <Box boxSize="2.5em" borderRadius="full" overflow="hidden">
            <Thumbnail height={300} width={300} />
          </Box>
          <VStack align="left">
            <Box
              overflow="hidden"
              height="1.25em"
              borderRadius="md"
              width="14em"
            >
              <Thumbnail width={300} height={200} />
            </Box>
            <Box
              overflow="hidden"
              height="0.75em"
              borderRadius="sm"
              width="10em"
            >
              <Thumbnail width={300} height={150} />
            </Box>
          </VStack>
        </HStack>
      </PodiumsCard>
    </Center>
  );
};

export const ShimmerPills: React.FC = () => {
  return (
    <Box my="0.75em">
      <HStack spacing={2} width="100%" overflow="hidden">
        {[...Array(4)].map((_, index) => (
          <Box
            key={index}
            borderRadius="full"
            overflow="hidden"
            height="2em"
            minW="fit-content"
          >
            <Thumbnail width={20 + (Math.random() + 1) * 60} height={200} />
          </Box>
        ))}
      </HStack>
      <Box
        width="4em"
        float="right"
        mt="-2em"
        pos="relative"
        height="2em"
        overflow="hidden"
        background="linear-gradient(to right, rgba(255,255,255,0) 0%,white)"
      />
    </Box>
  );
};

export const ShimmerListItemSearch = () => {
  return (
    <VStack spacing={4} align="left" width="100%">
      {[...Array(8)].map((_, index) => (
        <HStack width="100%" key={index}>
          <ShimmerThumbnail boxSize="3em" />
          <VStack align="left" width="60%">
            <Box
              overflow="hidden"
              height="1.15em"
              borderRadius="md"
              width="8em"
            >
              <Thumbnail width={300} height={200} />
            </Box>
            <Box
              overflow="hidden"
              height="0.7em"
              borderRadius="sm"
              width="100%"
            >
              <Thumbnail width={300} height={150} />
            </Box>
          </VStack>
        </HStack>
      ))}
    </VStack>
  );
};

export const ShimmerList: React.FC<{
  withTitle?: boolean;
}> = ({ withTitle }) => {
  return (
    <Center width="100%" px={3}>
      <PodiumsCard>
        <VStack spacing={4} align="left" width="100%">
          {withTitle && (
            <Box overflow="hidden" height="2em" borderRadius="md" width="10em">
              <Thumbnail width={300} height={200} />
            </Box>
          )}
          {[...Array(5)].map((_, index) => (
            <ShimmerCategoryItem key={index} />
          ))}
        </VStack>
      </PodiumsCard>
    </Center>
  );
};

export const ShimmerListOptions: React.FC = () => {
  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4} width="100%">
      {[...Array(10)].map((_, index) => (
        <GridItem
          key={index}
          borderRadius="lg"
          p="1em"
          position="relative"
          overflow={"hidden"}
        >
          <VStack justifyContent="center">
            <Icon as={FaSpinner} boxSize={"30px"} />
            <Heading fontSize="1em" aria-hidden>
              Loading
            </Heading>
          </VStack>
          <Box position="absolute" top={0} left={0}>
            <Thumbnail width={200} height={200} />
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
};

const ShimmerDiff: React.FC = () => {
  return (
    <PodiumsCard align="start">
      <HStack width="100%">
        <ShimmerThumbnail />
        <VStack align="left" width="70%">
          <Box overflow="hidden" height="1.25em" borderRadius="md" width="5em">
            <Thumbnail width={300} height={200} />
          </Box>
          <Box overflow="hidden" height="0.5em" borderRadius="sm" width="100%">
            <Thumbnail width={300} height={150} />
          </Box>
          <Box overflow="hidden" height="0.5em" borderRadius="sm" width="100%">
            <Thumbnail width={300} height={150} />
          </Box>
        </VStack>
      </HStack>
    </PodiumsCard>
  );
};

export const ShimmerListDiffs: React.FC = () => {
  return (
    <Center px={3} width="100%" boxSizing="border-box">
      <VStack width="100%">
        {[...Array(5)].map((_, index) => (
          <ShimmerDiff key={index} />
        ))}
      </VStack>
    </Center>
  );
};

export const UserShimmer = () => {
  return (
    <GradientBackground faded>
      <Header />
      <ShimmerUserProfileHeader />
      <ShimmerList withTitle />
    </GradientBackground>
  );
};

export const ShimmerUserSearchRow = () => {
  return (
    <>
      <PodiumsCard py="0.75em">
        <HStack width="100%">
          <Box boxSize="2.5em" borderRadius="full" overflow="hidden" mr="0.5em">
            <Thumbnail height={300} width={300} />
          </Box>
          <VStack align="left">
            <Box overflow="hidden" height="1em" borderRadius="md" width="10em">
              <Thumbnail width={300} height={200} />
            </Box>
            <Box
              overflow="hidden"
              height="0.75em"
              borderRadius="sm"
              width="14em"
            >
              <Thumbnail width={300} height={150} />
            </Box>
          </VStack>
        </HStack>
      </PodiumsCard>
    </>
  );
};
