import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";

export const initializeFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBUG4jbSfbVfgvgdZD8z9kQaGb1oWRibfA",
    authDomain: "fourth-return-410405.firebaseapp.com",
    projectId: "fourth-return-410405",
    storageBucket: "fourth-return-410405.appspot.com",
    messagingSenderId: "171803009815",
    appId: "1:171803009815:web:d3c42ed8416d5471fe4d52",
    measurementId: "G-GBFB1L1YBD",
  };

  // Initialize Firebase
  return initializeApp(firebaseConfig);
};

export const initializeSentry = () => {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://52a58cc7aee2b8dc3f27f492821a4871@o4507913285926912.ingest.us.sentry.io/4507913287696384",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
