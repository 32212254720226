import {
  Button,
  ButtonProps,
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useOAuth2 } from "@tasoskakour/react-use-oauth2";
import React from "react";
import { OAuthIntegrationProvider, User } from "src/types/user";
import { errorToast } from "src/utils";
import { refreshIntegration, sendAccessToken } from "../../networking";
import { CURRENT_USER_QUERY } from "../../state/userQueries";
import { isColorLight } from "../../utils";
import ListIcon from "../Lists/Utils/ListIcon";

interface OAuthButtonProps extends ButtonProps {
  navigate: (path: string) => void;
  provider: OAuthIntegrationProvider;
  listSlug: string;
  user?: User;
}

const OAuthLoginButton: React.FC<OAuthButtonProps> = ({
  navigate,
  provider,
  listSlug,
  user,
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { data, loading, error, getAuth, logout } = useOAuth2({
    authorizeUrl: provider.authorizeURL,
    clientId: provider.clientID,
    redirectUri: window.location.origin + "/callback",
    scope: provider.scope,
    responseType: "code",
    exchangeCodeForTokenServerURL:
      process.env.REACT_APP_SERVER_URL + "/callbacks/" + provider.slug,
    exchangeCodeForTokenMethod: "POST",
    onSuccess: async (payload: any) => {
      const data = await sendAccessToken(
        { ...payload, listSlug },
        provider.slug,
        navigate,
        toast
      );
      if (data.success) {
        queryClient.invalidateQueries({ queryKey: CURRENT_USER_QUERY });
        toast({
          title: "Integration Successful!",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else if (process.env.REACT_APP_DEBUG) {
        console.error(data);
      }
      navigate("/me/" + listSlug);
    },
    onError: (error_: any) => {
      if (process.env.REACT_APP_DEBUG) {
        console.error(error_);
      }
      errorToast("Error logging in", toast);
    },
  });

  const hoverBgColor = useColorModeValue(
    `${provider.color}.darken(10%)`,
    `${provider.color}.darken(10%)`
  );

  const handleRefreshIntegration = async () => {
    const refreshPromise = refreshIntegration(
      navigate,
      listSlug,
      provider.slug
    );
    toast.promise(refreshPromise, {
      success: (data) => {
        if (!data) {
          console.warn("Unable to refresh integration");
          getAuth();
          return {
            title: "Please reauthenticate with " + provider.name,
            description: "You may have to enable popups!",
            status: "warning",
          };
        }
        queryClient.invalidateQueries({ queryKey: CURRENT_USER_QUERY });
        setTimeout(() => navigate("/me/" + listSlug), 500);
        return { title: "Items pulled from " + provider.name + "!" };
      },
      error: { title: "Error", description: "Error updating items" },
      loading: { title: "Loading..." },
    });
  };

  const textColor = isColorLight(provider.color) ? "black" : "white";

  if (
    user &&
    user.oauthIntegrations.find((i) => i.providerSlug === provider.slug)
  ) {
    return (
      <Button
        onClick={handleRefreshIntegration}
        backgroundColor={provider.color}
        color={textColor}
        _hover={{ backgroundColor: hoverBgColor }}
      >
        <Flex align="center" justify="center">
          <ListIcon iconName={provider.slug} color={textColor} boxSize="24px" />
          <Text ml="8px">Import from {provider.name}</Text>
        </Flex>
      </Button>
    );
  } else {
    return (
      <Button
        onClick={getAuth}
        backgroundColor={provider.color}
        color={textColor}
        _hover={{ backgroundColor: hoverBgColor }}
      >
        <Flex align="center" justify="center">
          <ListIcon iconName={provider.slug} color={textColor} boxSize="24px" />{" "}
          <Text ml="8px">Sign in with {provider.name}</Text>
        </Flex>
      </Button>
    );
  }
};

export default OAuthLoginButton;
