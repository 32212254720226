import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";
import { getPinOptions } from "../networking";

export const pins = createQueryKeys("pin", {
  options: {
    queryKey: ["options"],
    queryFn: getPinOptions,
  },
});

export const useGetPinOptions = () => {
  return useQuery({ ...pins.options, staleTime: 1000 * 60 * 5, retry: false });
};
