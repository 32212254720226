import {
  Box,
  Button,
  Center,
  FormControl,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { FaPhone, FaUser } from "react-icons/fa6";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  GoogleLoginButton,
  SpotifyLoginButton,
} from "src/components/Auth/LoginButtons";
import { phoneRegex } from "src/constants";
import { checkUser } from "src/networking";
import { useLogin, useLoginWithFirebase } from "src/state/userQueries";
import { errorToast } from "src/utils";
import { BackButton } from "../Buttons";
import { PasswordInput } from "../Forms";
import { AppIconLogo } from "../Header/Header";
import ScreenshotSlider from "./ScreenshotSlider";
import VerifyPhone from "./VerifyPhoneInput";
import { minifyPhoneNumber } from "./verifyPhoneUtils";

enum LoginMethod {
  PHONE,
  EMAIL,
  USERNAME,
  PHONE_WITH_PASSWORD,
}

export const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [usernameEmailPhone, setUsernameEmailPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loginMethod, setLoginMethod] = useState<LoginMethod | null>(null);

  const toast = useToast();
  const navigate = useNavigate();
  const login = useLogin();
  const usernameRef = useRef<HTMLInputElement | null>(null);
  const loginWithFirebase = useLoginWithFirebase();

  const onContinue = async () => {
    usernameRef.current?.blur();
    setLoading(true);
    const domain = window.location.hostname;
    if (domain === "podiu.ms" || domain === "localhost") {
      try {
        const res = await checkUser(usernameEmailPhone);
        if (res.loginMethod && res.loginMethod.toLowerCase() !== "phone") {
          errorToast(
            `Please login with ${res.loginMethod} instead.`,
            toast,
            "top"
          );
        } else if (res.phone) {
          setLoginMethod(LoginMethod.PHONE);
        } else if (res.email) {
          setLoginMethod(LoginMethod.EMAIL);
        } else if (res.username) {
          setLoginMethod(LoginMethod.USERNAME);
        } else {
          toast({
            title: "Oops...",
            description: "No account with that info",
            position: "top",
            // TODO: add sign up button
          });
        }
      } catch (e) {
        errorToast("Error logging in", toast, "top");
      }
    } else {
      // This is on Vercel so we can't ping the backend
      if (phoneRegex.test(usernameEmailPhone)) {
        console.log("Phone number");
        setLoginMethod(LoginMethod.PHONE);
      } else {
        console.log("Email or username");
        setLoginMethod(LoginMethod.EMAIL);
      }
    }
    setLoading(false);
  };

  const handleSigninPromise = async (signInPromise: Promise<any>) => {
    await signInPromise
      .then((newUser) => {
        toast({
          title: "Welcome back!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/me");
      })
      .catch((error) => {
        if (error.response?.status === 400 || error.response?.status === 401) {
          errorToast(error.response.data.message, toast, "top");
        } else {
          // If the request was not successful, handle the error
          if (process.env.REACT_APP_DEBUG) {
            console.error("Error during authentication:", error);
          }
          errorToast("Error signing in", toast, "top");
        }
      });
  };

  const handleLogin = async (username: string, password: string) => {
    usernameRef.current?.focus();
    if (!username || !password) {
      errorToast("Username and password are both required.", toast, "top");
      return;
    }
    setLoading(true);
    const signInPromise = login.mutateAsync({ username, password });
    await handleSigninPromise(signInPromise);
    setLoading(false);
  };

  const handleFirebaseToken = async (token: string) => {
    if (!token) {
      errorToast(
        "Please try logging in with a username and password.",
        toast,
        "top"
      );
      return;
    }
    const signInPromise = loginWithFirebase.mutateAsync(token);
    handleSigninPromise(signInPromise);
  };

  if (loginMethod === null) {
    // Ask for phone, email, or username
    return (
      <HStack spacing="3em" width={{ base: "90%", md: "auto" }}>
        <Center display={{ base: "none", md: "block" }} w="400px" px="2em">
          <ScreenshotSlider />
        </Center>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onContinue();
          }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <VStack
            spacing={4}
            width={{ base: "100%", md: "350px" }}
            flex={1}
            maxW={{
              base: "450px",
              md: "350px",
            }}
          >
            <AppIconLogo />
            <Heading
              fontSize="36px"
              mb={2}
              color="topFive.primary"
              textAlign="center"
              fontWeight={600}
            >
              Log in
            </Heading>
            <SpotifyLoginButton />
            <GoogleLoginButton />
            <HStack spacing={3}>
              <Text fontWeight={600}>───────</Text>
              <Text fontWeight={600}>OR</Text>
              <Text fontWeight={600}>───────</Text>
            </HStack>
            <FormControl>
              <InputGroup>
                <InputLeftAddon bg="white" pl="12px" pr={0}>
                  <Icon as={FaUser} />
                </InputLeftAddon>
                <Input
                  type="text"
                  value={usernameEmailPhone}
                  onChange={(e) => setUsernameEmailPhone(e.target.value)}
                  placeholder="Username, phone, or email"
                  required
                  autoComplete="username"
                  autoCorrect="off"
                  autoCapitalize="none"
                  spellCheck="false"
                  ref={usernameRef}
                  enterKeyHint="next"
                  pl="10px"
                />
              </InputGroup>
            </FormControl>

            <Button
              w="100%"
              variant="dark"
              onClick={onContinue}
              isLoading={loading}
              type="submit"
              borderRadius="full"
            >
              Continue
            </Button>
            <HStack mt="1em">
              <Text fontSize="16px">Don't have an account? </Text>
              <Button
                as={RouterLink}
                to="/signup"
                size="sm"
                borderRadius="full"
                bg="white"
              >
                Sign up
              </Button>
            </HStack>
          </VStack>
        </form>
      </HStack>
    );
  } else if (
    loginMethod === LoginMethod.EMAIL ||
    loginMethod === LoginMethod.USERNAME ||
    loginMethod === LoginMethod.PHONE_WITH_PASSWORD
  ) {
    // User typed in email or username
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin(usernameEmailPhone, password);
        }}
        style={{ width: "100%" }}
      >
        <Center>
          <VStack spacing={4} w="100%" maxW="450px">
            <BackButton
              onClick={() => {
                setLoginMethod(null);
                setLoading(false);
              }}
              text="Back"
            />
            <InputGroup>
              <InputLeftAddon p="0.75em">
                {loginMethod === LoginMethod.EMAIL ||
                loginMethod === LoginMethod.USERNAME ? (
                  <Icon as={FaUser} />
                ) : (
                  <Icon as={FaPhone} />
                )}
              </InputLeftAddon>
              <Input
                type="text"
                value={usernameEmailPhone}
                readOnly
                bg="topFive.gray.light"
                ref={usernameRef}
              />
            </InputGroup>
            <PasswordInput
              password={password}
              setPassword={setPassword}
              autofocus
            />

            <Button
              w="100%"
              variant="dark"
              type="submit"
              isLoading={loading}
              borderRadius="full"
            >
              Log in
            </Button>
            <Text fontSize="18px">
              Forgot your password?{" "}
              <Link
                as={RouterLink}
                to={`/reset-password?account=${usernameEmailPhone}`}
                color="topFive.primary"
                fontWeight={700}
              >
                Reset
              </Link>
            </Text>
          </VStack>
        </Center>
      </form>
    );
  } else if (loginMethod === LoginMethod.PHONE) {
    return (
      <Box w="100%" maxW="450px" display="block" className="ferda">
        <VerifyPhone
          phoneNumber={minifyPhoneNumber(usernameEmailPhone)}
          usePassword={() => setLoginMethod(LoginMethod.PHONE_WITH_PASSWORD)}
          setFirebaseToken={handleFirebaseToken}
          onBack={() => setLoginMethod(null)}
        />
      </Box>
    );
  } else {
    throw new Error("Invalid step number");
  }
};
