import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PodiumsCard from "src/components/PodiumsCard";
import { BACKGROUND_FADED } from "src/constants";
import theme from "src/theme";
import Swiper from "swiper";
import HorizontalSwiper from "../Utils/HorizontalSwiper";

const Slide: React.FC<{
  title: string;
  desc: string;
  imageURL: string;
  className?: string;
}> = ({ title, desc, imageURL, className }) => {
  return (
    <Center width="100%" className="parallax-bg" data-swiper-parallax="0">
      <PodiumsCard mx="1em" py="2em" pb="0">
        <VStack spacing={4} overflow="hidden">
          <Heading as="h3">{title}</Heading>
          <Text textAlign="center" data-swiper-parallax="-10%">
            {desc}
          </Text>
          <SimpleDeviceMockup imageURL={imageURL} className={className} />
        </VStack>
      </PodiumsCard>
    </Center>
  );
};

const SimpleDeviceMockup: React.FC<{
  imageURL: string;
  className?: string;
}> = ({ imageURL, className }) => {
  return (
    <Box
      borderRadius="3xl"
      borderBottomRadius="0"
      border={"2px solid " + theme.colors.topFive.gray.light}
      borderBottom="none"
      overflow="hidden"
      maxW="50%"
      pt="0.5em"
      px="0.2em"
      mb="-1.5em"
      mt="0.5em"
      className={className}
      data-swiper-parallax="-40%"
    >
      <VStack spacing={1}>
        <Box
          borderRadius="full"
          bg={!!className ? "white" : "topFive.gray.light"}
          width="40px"
          height="10px"
        />
        <Image src={imageURL} />
      </VStack>
    </Box>
  );
};

const Slide1 = () => {
  return (
    <Slide
      title="Create lists"
      desc="From your top songs right now to the shows you're watching, share what you're into."
      imageURL="/bare-edit-list-screenshot.jpeg"
      className="bg-white"
    />
  );
};

const Slide2 = () => {
  return (
    <Slide
      title="Follow your friends"
      desc="See when your friends update their lists - you might find a new movie or podcast."
      imageURL="/bare-feed-screenshot.png"
      className="bg-party-gradient"
    />
  );
};

const Slide3 = () => {
  return (
    <Slide
      title="Share your profile"
      desc="Whether it's TikTok, Instagram, or Tinder, show off with your own link."
      imageURL="/bare-profile-screenshot.png"
      className="bg-blue-gradient"
    />
  );
};

const Onboarding = () => {
  const [swiper, setSwiper] = useState<Swiper>();
  const [hideSkip, setHideSkip] = useState(false);

  const handleChange = () => {
    if (!!swiper) {
      setHideSkip(swiper.activeIndex === 2);
    }
  };

  const handleSkip = () => {
    if (!!swiper) {
      swiper.slideTo(2);
    }
  };

  return (
    <Box w="100%">
      <HorizontalSwiper
        slides={[<Slide1 />, <Slide2 />, <Slide3 />]}
        style={{ paddingBottom: "74px", zIndex: 0 }}
        onSwiper={setSwiper}
        onSlideChange={handleChange}
        pagination
      />
      {!hideSkip && (
        <Center w="100%" mt="-60px" px={3}>
          <Box w="100%" maxW="450px">
            <Button
              borderRadius="full"
              size="sm"
              py="14px"
              onClick={handleSkip}
              bg={BACKGROUND_FADED}
            >
              Skip
            </Button>
          </Box>
        </Center>
      )}
    </Box>
  );
};

export default Onboarding;
