import { Box, Center, Heading, VStack } from "@chakra-ui/react";
import React from "react";
import { Header } from "src/components/Header/Header";
import ListDiff from "src/components/Lists/View/ListDiff";
import PodiumsCard from "src/components/PodiumsCard";
import { ShimmerListDiffs } from "src/components/Shimmer";
import GradientBackground from "src/components/User/UserBackground";
import { useGetDiffs } from "src/state/listQueries";

const ListDiffs: React.FC = () => {
  const { data: diffs, isLoading, isError } = useGetDiffs();

  if (isLoading) {
    return (
      <>
        <Header />
        <ShimmerListDiffs />
      </>
    );
  } else if (isError || !diffs || diffs.length === 0) {
    return (
      <>
        <Header />
        <Box py="1em" px={3}>
          <Center width="100%" height="80vh">
            <PodiumsCard>
              <Heading size="4xl">😥</Heading>
              <Heading>No updates yet!</Heading>
            </PodiumsCard>
          </Center>
        </Box>
      </>
    );
  } else {
    return (
      <GradientBackground>
        <Header />
        <Center px={3} pb="1em">
          <VStack width="100%" spacing={4}>
            {diffs.map((d) => (
              <ListDiff diff={d} key={d.updatedAt} />
            ))}
          </VStack>
        </Center>
      </GradientBackground>
    );
  }
};

export default ListDiffs;
