import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalContent,
  ModalOverlay,
  Slide,
  Spinner,
  Tag,
  TagLabel,
  TagLeftIcon,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { IoSparkles } from "react-icons/io5";

import { FaCheckCircle } from "react-icons/fa";
import {
  FaArrowLeft,
  FaArrowTrendUp,
  FaClock,
  FaListUl,
  FaMagnifyingGlass,
  FaPlus,
  FaSpotify,
  FaTrophy,
} from "react-icons/fa6";
import PodiumsCard from "src/components/PodiumsCard";
import { ShimmerListItemSearch, ShimmerPills } from "src/components/Shimmer";
import { TALL_IMAGE_LISTS } from "src/constants";
import { searchItems } from "src/networking";
import { useCurrentUser } from "src/state/userQueries";
import { ListItem as ListItemType } from "src/types/list";
import { errorToast, listNameToSlug } from "src/utils";
import { useGetRecommendations } from "../../../state/listQueries";
import { ActionButton } from "../../Buttons";
import ListItem from "../View/ListItem";

const SPOTIFY_DEMO_ACCOUNT = ["spotify.demo", "noah3"];

interface SearchProps {
  handleAddItem: (i: ListItemType) => void;
  handleRemoveItem: (i: ListItemType) => void;
  listName: string;
  modalStartsOpen?: boolean;
  existingItems?: ListItemType[];
}

const getRecsIcon = (name: string) => {
  switch (name) {
    case "My Recents":
      return FaClock;
    case "For You":
      return IoSparkles;
    case "Trending on Spotify":
      return FaSpotify;
    case "My All Time":
      return FaTrophy;
    case "Trending":
      return FaArrowTrendUp;
    case "Search":
      return FaMagnifyingGlass;
    default:
      return FaListUl;
  }
};

export const SearchField: React.FC<SearchProps> = ({
  handleAddItem,
  handleRemoveItem,
  listName,
  modalStartsOpen,
  existingItems,
}) => {
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
  const [allData, setAllData] = useState<ListItemType[] | undefined>();
  const [searchResults, setSearchResults] = useState<ListItemType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [timeLastChanged, setTimeLastChanged] = useState(0);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const initialRef = useRef<HTMLInputElement | null>(null);
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const toast = useToast();
  const { data: currentUser } = useCurrentUser();
  const existingItemIdentifiers = new Set(
    (existingItems ?? []).map((i) => i.title + i.subtitle + i.imageURI)
  );

  const listSlug = listNameToSlug(listName);
  const recs = useGetRecommendations(listSlug);

  useEffect(() => {
    if (!allData && recs.data?.some((i) => i.name === "All")) {
      setAllData(recs.data?.find((i) => i.name === "All")?.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recs.data, allData]);

  useEffect(() => {
    if (!!allData) {
      search();
      return;
    }

    const curTime = new Date().getTime();
    setTimeLastChanged(curTime);
    const delayDebounceFn = setTimeout(async () => {
      const curTime = new Date().getTime();
      if (
        searchTerm.trim().length >= 3 &&
        curTime - timeLastChanged >= 1000 &&
        !isLoading
      ) {
        await search();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const search = async () => {
    if (allData) {
      if (searchTerm.trim().length === 0) {
        setCurrentTab("All");
        return;
      }
      const data = allData.filter((i) =>
        i.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(data);
      setCurrentTab("Search");
      return;
    }
    if (searchTerm.trim().length === 0) {
      return;
    }
    try {
      setIsLoading(true);
      searchButtonRef.current?.focus();
      const data = await searchItems(listSlug, searchTerm);
      setSearchResults(data);
      setCurrentTab("Search");
      setIsLoading(false);
    } catch (err) {
      errorToast("Search Failed", toast, "top");
      setIsLoading(false);
      if (process.env.REACT_APP_DEBUG) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (modalStartsOpen) {
      onOpen();
    }

    const getRecs = async () => {
      if (recs.isLoading || !recs.data) {
        setIsLoading(true);
      } else if (recs.isError) {
        errorToast("Failed to get reccomendations", toast, "top");
        if (process.env.REACT_APP_DEBUG) {
          console.error(recs.error);
        }
      } else {
        setIsLoading(false);
        recs.data.length > 0 && setCurrentTab(recs.data[0].name);
      }
    };
    getRecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recs.data, recs.isError, recs.isLoading]);

  const isSpotifyUser = SPOTIFY_DEMO_ACCOUNT.includes(
    currentUser?.username ?? ""
  );

  const TabTitle: React.FC<{
    name: string;
  }> = ({ name }) => {
    const active = currentTab === name;
    return (
      <Tag
        size="md"
        py="0.4em"
        bg={active ? "topFive.secondary" : undefined}
        color={active ? "white" : undefined}
        onClick={() => setCurrentTab(name)}
        px="1em"
        borderRadius="full"
        minW="fit-content"
        cursor="pointer"
      >
        <TagLeftIcon as={getRecsIcon(name)} />
        <TagLabel>
          {name === "Trending"
            ? SPOTIFY_DEMO_ACCOUNT.includes(currentUser?.username ?? "")
              ? "Trending on Spotify"
              : name
            : name}
        </TagLabel>
      </Tag>
    );
  };

  return (
    <Box mb="1em" mt="0.5em" width="100%">
      <InputGroup size="md">
        <Input
          type={"text"}
          placeholder={`Find ${listName} to add...`}
          _placeholder={{ color: "gray.300", fontWeight: 600 }}
          onClick={onOpen}
          cursor="pointer"
          variant="outline"
          readOnly
        />
        <InputRightElement>
          <Button h="1.75rem" size="sm" type="submit" mr="8px">
            <Icon as={FaMagnifyingGlass} color="gray.700" fontSize="md" />
          </Button>
        </InputRightElement>
      </InputGroup>
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialRef}>
        <ModalOverlay />
        <ModalContent bg="rgba(0,0,0,0)" mt="0.5em">
          <Center width="100%" px="0.5em">
            <PodiumsCard minW="70vw" minH="95vh" bg="white">
              <form
                onSubmit={(e) => {
                  search();
                  e.preventDefault();
                }}
                style={{ width: "100%" }}
              >
                <HStack>
                  <ActionButton
                    icon={FaArrowLeft}
                    onClick={onClose}
                    color=""
                    mx="-0.25em"
                  />
                  <InputGroup size="md">
                    <Input
                      type="search"
                      enterKeyHint="search"
                      placeholder={`Find ${listName} to add...`}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      _placeholder={{ color: "gray.300", fontWeight: 600 }}
                      ref={initialRef}
                      fontWeight={500}
                      variant="outline"
                      autoFocus
                    />
                    <InputRightElement>
                      <Button
                        h="1.75rem"
                        size="sm"
                        type="submit"
                        mr="8px"
                        disabled={isLoading}
                        ref={searchButtonRef}
                      >
                        {isLoading ? (
                          <Spinner size="xs" />
                        ) : (
                          <Icon
                            as={FaMagnifyingGlass}
                            color="gray.700"
                            size="1em"
                          />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </HStack>
                {isLoading ? (
                  <>
                    <ShimmerPills />
                    <ShimmerListItemSearch />
                  </>
                ) : (
                  <>
                    <Box
                      color="topFive.secondary"
                      mt="0.75em"
                      mx="-1em"
                      px={3}
                      pos="relative"
                      overflowX="scroll"
                      whiteSpace="nowrap"
                      sx={{
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        msOverflowStyle: "none", // for Internet Explorer and Edge
                        scrollbarWidth: "none", // for Firefox
                      }}
                    >
                      <Flex gap="0.5em">
                        <Slide
                          in={
                            !!allData
                              ? searchTerm.length > 0
                              : searchResults.length > 0
                          }
                          unmountOnExit
                          direction="left"
                          style={{ position: "relative", width: "auto" }}
                        >
                          <TabTitle name="Search" />
                        </Slide>
                        {isSpotifyUser ? (
                          <TabTitle name="Trending on Spotify" />
                        ) : (
                          recs.data?.map(({ name, items }) => {
                            return <TabTitle name={name} />;
                          })
                        )}
                        <Box minWidth="0.25em" />
                      </Flex>
                    </Box>
                    <Box
                      width="1.5em"
                      float="right"
                      mt="-2em"
                      mr="-1em"
                      pos="relative"
                      height="2em"
                      overflow="hidden"
                      background="linear-gradient(to right, rgba(255,255,255,0) 0%,white)"
                    />
                    <Box width="100%" mt="0.75em">
                      {(currentTab === "Search"
                        ? searchResults
                        : isSpotifyUser // Only Show Trending
                        ? recs.data?.find((i) => i.name === "Trending")
                            ?.items ?? []
                        : recs.data?.find((i) => i.name === currentTab)
                            ?.items ?? []
                      )
                        // .slice(0, 10)
                        .map((item: ListItemType, index) => (
                          <ListItem
                            key={index}
                            item={item}
                            editMode="true"
                            editAction={{
                              icon: (existingItemIdentifiers ?? new Set()).has(
                                item.title + item.subtitle + item.imageURI
                              )
                                ? FaCheckCircle
                                : FaPlus,
                            }}
                            tallImage={TALL_IMAGE_LISTS.includes(listSlug)}
                            allowDrag={false}
                            startAnimating={true}
                            cursor="pointer"
                            onClick={() => {
                              if (
                                (existingItemIdentifiers ?? new Set()).has(
                                  item.title + item.subtitle + item.imageURI
                                )
                              ) {
                                handleRemoveItem(item);
                              } else {
                                handleAddItem(item);
                              }
                            }}
                            short
                          />
                        ))}
                    </Box>
                  </>
                )}
              </form>
            </PodiumsCard>
          </Center>
        </ModalContent>
      </Modal>
    </Box>
  );
};
