import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaHome, FaSignInAlt } from "react-icons/fa";
import { FaBars, FaUser } from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";
import { BACKGROUND_FADED } from "src/constants";
import { useCurrentUser } from "src/state/userQueries";
import { ProfilePicture } from "../User/UserProfileHeader";
import { Logo } from "./Header";

export const HomePageHeader = () => {
  const [isHome, setIsHome] = useState(false);
  useEffect(() => {
    if (window.location.pathname === "/") {
      setIsHome(true);
    }
  }, []);
  const { data, isLoading, isError } = useCurrentUser();
  const AccountButtons = () => {
    if (isLoading) {
      return (
        <Button
          variant="light"
          colorScheme="primary"
          leftIcon={<Spinner size="sm" />}
          borderRadius="full"
          bg="white"
          disabled
        >
          My Account
        </Button>
      );
    } else if (!data || isError) {
      return (
        <Box>
          <ButtonGroup display={{ base: "none", md: "block" }}>
            <Button
              variant="light"
              colorScheme="primary"
              as={RouterLink}
              to="/login"
              leftIcon={<FaSignInAlt />}
              borderRadius="full"
              bg="white"
            >
              Login
            </Button>
            <Button
              variant="light"
              colorScheme="primary"
              as={RouterLink}
              to="/signup"
              leftIcon={<FaUser />}
              borderRadius="full"
              bg="white"
            >
              Sign up
            </Button>
          </ButtonGroup>
          <Box display={{ base: "block", md: "none" }}>
            <Menu>
              <MenuButton
                aria-label="Options"
                bg="none"
                p={0}
                color={BACKGROUND_FADED}
                fontSize="2em"
                as={IconButton}
                icon={<FaBars />}
                _hover={{ bg: "none", color: "topFive.gray.light" }}
                _active={{ bg: "none", color: "topFive.gray.light" }}
              ></MenuButton>
              <MenuList
                boxShadow="0px 8px 20px 0px rgba(0,0,0,0.2)"
                zIndex={100}
              >
                {!isHome && (
                  <MenuItem icon={<FaHome />} as={RouterLink} to="/">
                    What's Podiums?
                  </MenuItem>
                )}
                <MenuItem icon={<FaSignInAlt />} as={RouterLink} to="/login">
                  Login
                </MenuItem>
                <MenuItem icon={<FaUser />} as={RouterLink} to="/signup">
                  Sign up
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Box>
      );
    } else {
      return (
        <Button
          variant="light"
          colorScheme="primary"
          as={RouterLink}
          to={`/${data.username}`}
          leftIcon={<ProfilePicture src={data.profilePicture} size={1.5} />}
          borderRadius="full"
          bg="white"
        >
          My Account
        </Button>
      );
    }
  };

  return (
    <HStack
      px={3}
      py="0.75em"
      justify="space-between"
      alignItems="center"
      width="100%"
      className={isHome ? "bg-party-gradient" : undefined}
    >
      <Logo height="40px" long />
      <AccountButtons />
    </HStack>
  );
};
