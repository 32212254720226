import {
  Box,
  Circle,
  Fade,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { FaBars } from "react-icons/fa6";
import { ListItem as ListItemType } from "src/types/list";
import { ActionButtonProps } from "../../Buttons";

export function animateLayoutChanges(args: any) {
  const { isSorting, wasDragging } = args;

  if (isSorting || wasDragging) {
    return defaultAnimateLayoutChanges(args);
  }

  return true;
}

interface ListItemProps {
  rank?: number;
  item: ListItemType;
  editMode?: string;
  editAction?: ActionButtonProps;
  allowDrag: boolean;
  startAnimating: boolean;
  tallImage: boolean;
  linkEnabled?: boolean;
  [key: string]: any;
  short?: boolean;
}

const ListItem: React.FC<ListItemProps> = ({
  rank,
  item,
  editMode,
  allowDrag,
  editAction,
  startAnimating,
  tallImage,
  short,
  ...rest
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      animateLayoutChanges,
      id: item._id ?? item.title + item.imageURI,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "auto",
  };

  return (
    <HStack
      ref={setNodeRef}
      style={style}
      display="flex"
      alignItems="center"
      mb="1em"
      {...rest}
    >
      {rank && (
        <Circle
          bg="gray.700"
          color="white"
          size={12}
          alignItems="center"
          justifyContent="center"
          mr={-8}
          zIndex="0"
        >
          <Text ml={-5} fontWeight="bold" fontSize="md">
            {rank}
          </Text>
        </Circle>
      )}
      <Box h="100%" w="100%">
        <HStack height="100%" position="relative" justify="space-between">
          <HStack spacing={short ? "0.75em" : "1em"}>
            <Image
              src={item.imageURI}
              alt={item.title}
              width={
                short
                  ? tallImage
                    ? "2em"
                    : "3em"
                  : tallImage
                  ? "3.33em"
                  : "5em"
              }
              height={short ? "3em" : "5em"}
              objectFit="cover"
              boxShadow="0px 14px 20px 0px rgba(0,0,0,0.2)"
              borderRadius={
                localStorage.getItem("borderRadiusDisabled") === "true"
                  ? undefined
                  : "4px"
              }
              background="white"
            />

            <VStack spacing="1" justify="center" align="start" textAlign="left">
              <Heading
                size={
                  item.subtitle ? (short ? "xs" : "sm") : short ? "sm" : "md"
                }
              >
                {item.title}
              </Heading>

              {item.subtitle && (
                <Heading
                  fontSize={short ? "0.75em" : "0.9em"}
                  fontWeight={500}
                  color="gray.700"
                  textAlign="left"
                >
                  {item.subtitle}
                </Heading>
              )}
            </VStack>
          </HStack>
          <Fade in={startAnimating} unmountOnExit>
            {editAction && (
              <VStack spacing={3} style={{ touchAction: "none" }}>
                <Icon
                  as={editAction.icon}
                  fontSize={allowDrag || short ? "xl" : "2xl"}
                  onClick={editAction.action}
                />
                {allowDrag && (
                  <Icon
                    cursor="grab"
                    as={FaBars}
                    fontSize="2xl"
                    {...listeners}
                    {...attributes}
                  />
                )}
              </VStack>
            )}
          </Fade>
        </HStack>
      </Box>
    </HStack>
  );
};

export default ListItem;
