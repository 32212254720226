import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  IconButton,
  Input,
  useEditableControls,
} from "@chakra-ui/react";
import React from "react";
import { FaCheck, FaPenToSquare, FaXmark } from "react-icons/fa6";

interface EditableTextFieldProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit?: (value: string) => void;
}

const EditableTextField: React.FC<EditableTextFieldProps> = ({
  value,
  onChange,
  onSubmit,
}) => {
  const EditableControls = () => {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup size="sm">
        <IconButton
          as={FaCheck}
          {...getSubmitButtonProps()}
          size="1em"
          aria-label="Submit"
        />
        <IconButton
          as={FaXmark}
          {...getCancelButtonProps()}
          size="1em"
          aria-label="Cancel"
        />
      </ButtonGroup>
    ) : (
      <IconButton
        size="1em"
        ml="0.5em"
        borderRadius={0}
        bg="none"
        as={FaPenToSquare}
        {...getEditButtonProps()}
        aria-label="Edit"
      />
    );
  };

  return (
    <Editable
      value={value}
      fontSize="md"
      onChange={onChange}
      onSubmit={onSubmit}
    >
      <HStack>
        <EditablePreview />
        <Input as={EditableInput} />
        <EditableControls />
      </HStack>
    </Editable>
  );
};

export default EditableTextField;
