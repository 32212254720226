import { Heading, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditList from "src/components/Lists/Edit/EditList";
import PodiumsCard from "src/components/PodiumsCard";
import { ShimmerList } from "src/components/Shimmer";
import GradientBackground from "src/components/User/UserBackground";
import { useCurrentUser, useGetUser } from "src/state/userQueries";
import "../backgrounds.css";
import { Header } from "../components/Header/Header";
import List from "../components/Lists/View/List";

const ListView = () => {
  const { username, listSlug } = useParams();
  // const thirdListDisclosure = useDisclosure();
  let [editMode, setEditMode] = useState("");

  const navigate = useNavigate();
  const { data: currentUser, isLoading: curUserLoading } = useCurrentUser();

  const onMyProfile = !!currentUser && currentUser.username === username;
  const { data: getUserData, isLoading: getUserLoading } = useGetUser(
    username ?? "",
    !onMyProfile
  );

  const user = onMyProfile ? currentUser! : getUserData;

  useEffect(() => {
    if (username !== username?.toLowerCase()) {
      navigate(`/${username?.toLowerCase()}`, { replace: true });
    }

    // const queryParams = new URLSearchParams(window.location.search);
    // const isThirdList = queryParams.get("third");
    // if (isThirdList !== null) {
    //   thirdListDisclosure.onOpen();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `Podiums — @${user?.username}`;
  }, [user]);

  if (getUserLoading || curUserLoading) {
    return (
      <GradientBackground>
        <Header />
        <ShimmerList withTitle />
      </GradientBackground>
    );
  } else {
    const list = user?.lists.find((l) => l.slug === listSlug);
    if (!user || !list) {
      return (
        <GradientBackground>
          <Header />
          <VStack position="relative" spacing="1em" p="1em">
            <PodiumsCard>
              <Heading size="4xl">😥</Heading>
              <Heading>{!user ? "User" : "List"} not found</Heading>
            </PodiumsCard>
          </VStack>
        </GradientBackground>
      );
    } else {
      return (
        <GradientBackground theme={user.backgroundType}>
          <Header />
          <VStack position="relative" spacing="1em" width="100%">
            {editMode === "" ? (
              <List
                user={user}
                list={list}
                editMode={editMode}
                setEditMode={onMyProfile ? setEditMode : undefined}
                onMyProfile={onMyProfile}
              />
            ) : (
              <EditList
                user={user}
                list={
                  user.lists.find((list) => list.slug === editMode) ||
                  user.lists[0] // Fallback to first list, but this shouldn't happen
                }
                editMode={editMode}
                setEditMode={setEditMode ?? (() => {})}
              />
            )}
          </VStack>
          {/* <PodiumsDialog
            title="Congrats!"
            message="You've created 3 lists! You now have access to Pins on your profile."
            disclosure={thirdListDisclosure}
            onSubmit={() => {
              navigate(`/${user.username}`, { replace: true });
            }}
          /> */}
        </GradientBackground>
      );
    }
  }
};

export default ListView;
