import { Button, Icon, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { FaPlus } from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";
import { BACKGROUND_FADED } from "src/constants";

export const FloatingCreateButton: React.FC<{ isFirstList: boolean }> = ({
  isFirstList,
}) => {
  return (
    <VStack
      position="fixed"
      bottom="14px"
      right="14px"
      overflow="visible"
      zIndex={3}
    >
      {/* <Button
        as={RouterLink}
        to="/create/list"
        borderRadius="full"
        bg={BACKGROUND_FADED}
        backdropFilter="auto"
        backdropBlur="8px"
        boxShadow="0px 0px 15px rgba(0, 0, 0, 0.3)"
        fontSize="1.25em"
        height="3em"
        w="3em"
        display="flex"
        justifyContent="center"
        alignItems="center"
        aria-label="Create list"
      >
        <Icon as={FaPen} fontSize="1.25em" />
      </Button> */}
      <Button
        as={RouterLink}
        to="/create/list"
        borderRadius="full"
        bg={BACKGROUND_FADED}
        backdropFilter="auto"
        backdropBlur="8px"
        boxShadow="0px 0px 15px rgba(0, 0, 0, 0.3)"
        fontSize="1.25em"
        height={isFirstList ? "2.5em" : "3em"}
        w={isFirstList ? "2.5em" : "3em"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        aria-label="Create list"
      >
        <Icon as={FaPlus} fontSize={isFirstList ? "1.25em" : "1.75em"} />
        {isFirstList && <Text ml="6px">Create</Text>}
      </Button>
    </VStack>
  );
};
