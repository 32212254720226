import {
  Box,
  Button,
  Center,
  Collapse,
  Fade,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaRegGem } from "react-icons/fa";
import { FaChevronLeft, FaEyeSlash, FaSpotify } from "react-icons/fa6";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  BACKDROP_BLUR,
  BACKGROUND_FADED,
  BACKGROUND_HIDDEN,
  TALL_IMAGE_LISTS,
  borderRadiusDisabledUsers,
} from "src/constants";
import { SanitizedOtherUser } from "src/types/user";
import { relativeDate } from "src/utils";
import { UserList } from "../../../types/list";
import PodiumsCard from "../../PodiumsCard";
import { ProfilePicture } from "../../User/UserProfileHeader";
import {
  useDeleteListToast,
  useListRefresh,
  useListToggleHidden,
} from "../Edit/EditList";
import { EditListOptions } from "../Edit/EditListOptions";
import ListDialogs from "../Utils/ListDialogs";
import { UpdateSpotifyButton } from "../Utils/ListIcon";
import ItemMenuButton from "./ItemMenuButton";
import ListGrid from "./ListGrid";
import ListItem from "./ListItem";

export const ItemsHiddenButton: React.FC<{ onOpen: () => void }> = ({
  onOpen,
}) => {
  return (
    <>
      <Button onClick={onOpen} pos="relative" bg="rgba(0,0,0,0)" height="2em">
        <HStack width="100%" justify="center">
          <Icon as={FaEyeSlash} mr="7px" />
          <Text>Hidden on profile</Text>
        </HStack>
      </Button>
    </>
  );
};

interface ListProps {
  user: SanitizedOtherUser;
  list: UserList;
  editMode?: string;
  setEditMode?: (id: string) => void;
  onMyProfile?: boolean;
}

const List: React.FC<ListProps> = ({
  user,
  list,
  editMode,
  setEditMode,
  onMyProfile,
}) => {
  const [isHidden, setIsHidden] = useState(list.hidden ?? false);
  const [isGrid, setIsGrid] = useState(
    localStorage.getItem("listGridMode") === "true"
  );

  const navigate = useNavigate();
  const deleteDialogDisclosure = useDisclosure();
  const spotifyDisclosure = useDisclosure();
  const limitedDisclosure = useDisclosure();
  const onRefresh = useListRefresh(
    list.slug,
    () => navigate(`/${user.username}/${list.slug}`),
    list.integrationProviderSlug
  );

  const onDelete = useDeleteListToast(list, () => {});
  const onToggleHidden = useListToggleHidden(list);

  const onToggleGrid = () => {
    if (isGrid) {
      setIsGrid(false);
      localStorage.setItem("listGridMode", "false");
    } else {
      setIsGrid(true);
      localStorage.setItem("listGridMode", "true");
    }
  };

  const tallImages = TALL_IMAGE_LISTS.includes(list.slug);
  const isSpotify = list.integrationProviderSlug === "spotify";
  const isLimited = list.limitedEdition ?? false;

  return (
    <>
      <Center px={3} pb="1.5em" width="100%">
        <VStack width="100%" spacing={0}>
          <PodiumsCard
            p={0}
            zIndex="2"
            boxShadow="lg"
            bg={BACKGROUND_FADED}
            backdropFilter="auto"
            backdropBlur={BACKDROP_BLUR}
          >
            <Collapse in={isHidden} style={{ width: "100%" }}>
              <Box
                bg={BACKGROUND_HIDDEN}
                p="0.5em 1em"
                width="100%"
                borderTopRadius="xl"
              >
                <Icon as={FaEyeSlash} mr="7px" mb="-2px" />
                Hidden on profile
              </Box>
            </Collapse>
            <Box
              px="1.25em"
              pt={isHidden ? "0.5em" : "1em"}
              width="100%"
              pb="1em"
            >
              <HStack
                justify="space-between"
                align="center"
                mb="0.75em"
                ml="-0.5em"
              >
                <RouterLink to={"/" + user.username}>
                  <HStack>
                    <Icon as={FaChevronLeft} aria-label="Back to profile" />
                    <ProfilePicture
                      src={user.profilePicture}
                      size={2}
                      disableClick
                    />
                    <VStack align="start" spacing={0}>
                      <Heading fontSize="0.75em" fontWeight={500}>
                        @{user.username}
                      </Heading>
                      <Heading fontSize="lg">{list.name}</Heading>
                    </VStack>
                  </HStack>
                </RouterLink>
                <HStack spacing={4}>
                  {isSpotify &&
                    (list.updateAutomatically ? (
                      <UpdateSpotifyButton onClick={spotifyDisclosure.onOpen} />
                    ) : (
                      <Icon
                        as={FaSpotify}
                        boxSize="2em"
                        onClick={spotifyDisclosure.onOpen}
                        cursor="pointer"
                      />
                    ))}
                  {isLimited && (
                    <Icon
                      as={FaRegGem}
                      boxSize="1.75em"
                      onClick={limitedDisclosure.onOpen}
                      cursor="pointer"
                    />
                  )}
                  {setEditMode && (
                    <Fade in={editMode === ""} unmountOnExit>
                      <EditListOptions
                        hidden={isHidden}
                        onToggleHidden={() =>
                          setIsHidden(onToggleHidden(!isHidden))
                        }
                        onToggleEdit={() =>
                          list.updateAutomatically
                            ? spotifyDisclosure.onOpen()
                            : setEditMode(list.slug)
                        }
                        onDelete={deleteDialogDisclosure.onOpen}
                        isGrid={isGrid}
                        onToggleGrid={onToggleGrid}
                      />
                    </Fade>
                  )}
                </HStack>
              </HStack>
              <Box width="100%">
                {isGrid && onMyProfile ? (
                  <ListGrid
                    items={list.items}
                    tallImages={tallImages}
                    borderRadius={
                      localStorage.getItem("borderRadiusDisabled") !== "true" ||
                      !borderRadiusDisabledUsers.includes(user.username)
                    }
                  />
                ) : (
                  list.items.map((item, i) => (
                    <ItemMenuButton link={item.link} key={i}>
                      <ListItem
                        key={i}
                        item={item}
                        rank={i + 1}
                        allowDrag={false}
                        tallImage={tallImages}
                        startAnimating={false}
                      />
                    </ItemMenuButton>
                  ))
                )}
              </Box>
              {list.updatedAt && (
                <Text>updated {relativeDate(list.updatedAt)}</Text>
              )}
            </Box>
          </PodiumsCard>
        </VStack>
      </Center>
      <ListDialogs
        onMyProfile={onMyProfile ?? false}
        list={list}
        deleteDisclosure={deleteDialogDisclosure}
        spotifyDisclosure={spotifyDisclosure}
        limitedDisclosure={isLimited ? limitedDisclosure : undefined}
        onDelete={onDelete}
        onRefresh={onRefresh}
      />
    </>
  );
};

export default List;
