import { Circle, Icon, Image } from "@chakra-ui/react";
import React from "react";
import {
  FaBaseballBall,
  FaBookReader,
  FaCamera,
  FaCarAlt,
  FaClipboardList,
  FaCoffee,
  FaCompactDisc,
  FaFootballBall,
  FaHiking,
  FaMusic,
  FaPaintBrush,
  FaShoppingBag,
  FaSnapchatGhost,
  FaSnowboarding,
  FaTools,
  FaUsers,
} from "react-icons/fa";
import {
  FaApple,
  FaBagShopping,
  FaBaseball,
  FaBasketball,
  FaBook,
  FaBreadSlice,
  FaCode,
  FaDumbbell,
  FaFacebook,
  FaFootball,
  FaFutbol,
  FaGamepad,
  FaHorse,
  FaInstagram,
  FaLinkedin,
  FaMobileScreen,
  FaPalette,
  FaPersonBiking,
  FaPersonRunning,
  FaPersonSwimming,
  FaPinterest,
  FaPlane,
  FaPodcast,
  FaSeedling,
  FaSpotify,
  FaTableTennisPaddleBall,
  FaTiktok,
  FaTwitch,
  FaVolleyball,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";

import {
  GiAquarius,
  GiAries,
  GiCancer,
  GiCapricorn,
  GiClothes,
  GiConverseShoe,
  GiCook,
  GiGemini,
  GiLeo,
  GiLibra,
  GiPisces,
  GiRunningShoe,
  GiSagittarius,
  GiScorpio,
  GiShuttlecock,
  GiTaurus,
  GiVirgo,
} from "react-icons/gi";
import { IoGolfSharp, IoTennisballSharp } from "react-icons/io5";
import {
  MdOutlineSportsCricket,
  MdSkateboarding,
  MdSportsGymnastics,
  MdSportsHockey,
  MdSportsKabaddi,
  MdSurfing,
} from "react-icons/md";
import { RiBoxingFill } from "react-icons/ri";
import {
  SiAdidas,
  SiNike,
  SiPuma,
  SiThenorthface,
  SiZara,
} from "react-icons/si";
import { TbBrandVsco } from "react-icons/tb";

function getStringAfterDash(input: string): string {
  const parts = input.split("-");
  if (parts.length > 1) {
    return parts.slice(1).join("-");
  }
  return "";
}

const PinIcon: React.FC<{
  slug: string;
  imageURI?: string;
  [key: string]: any;
}> = ({ slug, imageURI, ...rest }) => {
  if (!!imageURI) {
    return (
      <Image
        src={imageURI}
        boxSize="2em"
        objectFit="cover"
        alt={slug}
        borderRadius="full"
      />
    );
  }
  if (slug.startsWith("colors-")) {
    return <ColorIcon color={slug} />;
  }
  if (slug.startsWith("sports-")) {
    return <SportsIcon sport={slug} />;
  }
  if (slug.startsWith("brands-")) {
    return <BrandIcon brand={slug} />;
  }
  if (slug.startsWith("hobbies-")) {
    return <HobbiesIcon hobby={slug} />;
  }
  if (slug.startsWith("zodiac-")) {
    return <ZodiacIcon zodiac={slug} />;
  }
  if (slug.startsWith("apps-")) {
    return <AppIcon app={slug} />;
  }

  // Top-level pin options (some of them are pins themselves - leaf nodes)
  let iconComponent;
  switch (slug) {
    case "songs":
      iconComponent = FaMusic;
      break;
    case "artists":
      iconComponent = FaUsers;
      break;
    case "albums":
      iconComponent = FaCompactDisc;
      break;
    case "colors":
      iconComponent = FaPalette;
      break;
    case "brands":
      iconComponent = FaBagShopping;
      break;
    case "coffees":
      iconComponent = FaCoffee;
      break;
    case "sports":
      iconComponent = FaBasketball;
      break;
    case "styles":
      iconComponent = GiClothes;
      break;
    case "shoes":
      iconComponent = GiRunningShoe;
      break;
    case "hobbies":
      iconComponent = FaBookReader;
      break;
    case "apps":
      iconComponent = FaMobileScreen;
      break;
    default:
      iconComponent = FaClipboardList;
  }
  // TODO hash the slug into a color
  return <Icon as={iconComponent} boxSize="2em" {...rest} />;
};

const SportsIcon: React.FC<{ sport: string }> = ({ sport }) => {
  let iconComponent;
  switch (sport) {
    case "sports-basketball":
      iconComponent = FaBasketball;
      break;
    case "sports-football":
      iconComponent = FaFootball;
      break;
    case "sports-baseball":
      iconComponent = FaBaseballBall;
      break;
    case "sports-soccer":
      iconComponent = FaFutbol;
      break;
    case "sports-hockey":
      iconComponent = MdSportsHockey;
      break;
    case "sports-tennis":
      iconComponent = IoTennisballSharp;
      break;
    case "sports-gym":
      iconComponent = FaDumbbell;
      break;
    case "sports-golf":
      iconComponent = IoGolfSharp;
      break;
    case "sports-wrestling":
      iconComponent = MdSportsKabaddi;
      break;
    case "sports-boxing":
      iconComponent = RiBoxingFill;
      break;
    case "sports-auto-racing":
      iconComponent = FaCarAlt;
      break;
    case "sports-track-and-field":
      iconComponent = FaPersonRunning;
      break;
    case "sports-mma":
      iconComponent = MdSportsKabaddi;
      break;
    case "sports-swimming":
      iconComponent = FaPersonSwimming;
      break;
    case "sports-volleyball":
      iconComponent = FaVolleyball;
      break;
    case "sports-lacrosse":
      iconComponent = MdOutlineSportsCricket;
      break;
    case "sports-softball":
      iconComponent = FaBaseball;
      break;
    case "sports-rugby":
      iconComponent = FaFootballBall;
      break;
    case "sports-gymnastics":
      iconComponent = MdSportsGymnastics;
      break;
    case "sports-skateboarding":
      iconComponent = MdSkateboarding;
      break;
    case "sports-surfing":
      iconComponent = MdSurfing;
      break;
    case "sports-snowboarding":
      iconComponent = FaSnowboarding;
      break;
    case "sports-cycling":
      iconComponent = FaPersonBiking;
      break;
    case "sports-horse-racing":
      iconComponent = FaHorse;
      break;
    case "sports-badminton":
      iconComponent = GiShuttlecock;
      break;
    case "sports-table-tennis":
      iconComponent = FaTableTennisPaddleBall;
      break;
    default:
      iconComponent = FaFootball;
  }
  return <Icon as={iconComponent} boxSize="2em" />;
};

const HobbiesIcon: React.FC<{ hobby: string }> = ({ hobby }) => {
  if (hobby === "hobbies-thrifting") {
    return <Icon as={FaShoppingBag} boxSize="2em" />;
  } else if (hobby === "hobbies-baking") {
    return <Icon as={FaBreadSlice} boxSize="2em" />;
  } else if (hobby === "hobbies-gaming") {
    return <Icon as={FaGamepad} boxSize="2em" />;
  } else if (hobby === "hobbies-photography") {
    return <Icon as={FaCamera} boxSize="2em" />;
  } else if (hobby === "hobbies-crafting") {
    return <Icon as={FaPaintBrush} boxSize="2em" />;
  } else if (hobby === "hobbies-reading") {
    return <Icon as={FaBook} boxSize="2em" />;
  } else if (hobby === "hobbies-drawing-painting") {
    return <Icon as={FaPalette} boxSize="2em" />;
  } else if (hobby === "hobbies-gym") {
    return <Icon as={FaDumbbell} boxSize="2em" />;
  } else if (hobby === "hobbies-music") {
    return <Icon as={FaMusic} boxSize="2em" />;
  } else if (hobby === "hobbies-traveling") {
    return <Icon as={FaPlane} boxSize="2em" />;
  } else if (hobby === "hobbies-gardening") {
    return <Icon as={FaSeedling} boxSize="2em" />;
  } else if (hobby === "hobbies-coding") {
    return <Icon as={FaCode} boxSize="2em" />;
  } else if (hobby === "hobbies-cooking") {
    return <Icon as={GiCook} boxSize="2em" />;
  } else if (hobby === "hobbies-dancing") {
    return <Icon as={MdSportsGymnastics} boxSize="2em" />;
  } else if (hobby === "hobbies-podcasting") {
    return <Icon as={FaPodcast} boxSize="2em" />;
  } else if (hobby === "hobbies-hiking") {
    return <Icon as={FaHiking} boxSize="2em" />;
  } else if (hobby === "hobbies-diy-projects") {
    return <Icon as={FaTools} boxSize="2em" />;
  }

  return <Image boxSize="2em" />;
};

const BrandIcon: React.FC<{ brand: string }> = ({ brand }) => {
  if (brand === "brands-nike") {
    return <Icon as={SiNike} boxSize="2em" />;
  } else if (brand === "brands-adidas") {
    return <Icon as={SiAdidas} boxSize="2em" />;
  } else if (brand === "brands-puma") {
    return <Icon as={SiPuma} boxSize="2em" />;
  } else if (brand === "brands-converse") {
    return <Icon as={GiConverseShoe} boxSize="2em" />;
  } else if (brand === "brands-the-north-face") {
    return <Icon as={SiThenorthface} boxSize="2em" />;
  } else if (brand === "brands-zara") {
    return <Icon as={SiZara} boxSize="2em" />;
  } else return <Image boxSize="2em" />;
};

const ColorIcon: React.FC<{ color: string }> = ({ color }) => {
  if (color === "colors-mustard") {
    return <Circle bg="#FFDB58" size="2em"></Circle>;
  } else if (color === "colors-peach") {
    return <Circle bg="#FFE5B4" size="2em"></Circle>;
  } else if (color === "colors-mint") {
    return <Circle bg="#3EB489" size="2em"></Circle>;
  } else if (color === "colors-burgundy") {
    return <Circle bg="#800020" size="2em"></Circle>;
  }
  const afterDash = getStringAfterDash(color);
  return <Circle bg={afterDash} size="2em"></Circle>;
};

const ZodiacIcon: React.FC<{ zodiac: string }> = ({ zodiac }) => {
  if (zodiac === "zodiac-aries") {
    return <Icon as={GiAries} boxSize="2em" />;
  } else if (zodiac === "zodiac-taurus") {
    return <Icon as={GiTaurus} boxSize="2em" />;
  } else if (zodiac === "zodiac-gemini") {
    return <Icon as={GiGemini} boxSize="2em" />;
  } else if (zodiac === "zodiac-cancer") {
    return <Icon as={GiCancer} boxSize="2em" />;
  } else if (zodiac === "zodiac-leo") {
    return <Icon as={GiLeo} boxSize="2em" />;
  } else if (zodiac === "zodiac-virgo") {
    return <Icon as={GiVirgo} boxSize="2em" />;
  } else if (zodiac === "zodiac-libra") {
    return <Icon as={GiLibra} boxSize="2em" />;
  } else if (zodiac === "zodiac-scorpio") {
    return <Icon as={GiScorpio} boxSize="2em" />;
  } else if (zodiac === "zodiac-sagittarius") {
    return <Icon as={GiSagittarius} boxSize="2em" />;
  } else if (zodiac === "zodiac-capricorn" || zodiac === "zodiac-signs") {
    return <Icon as={GiCapricorn} boxSize="2em" />;
  } else if (zodiac === "zodiac-aquarius") {
    return <Icon as={GiAquarius} boxSize="2em" />;
  } else if (zodiac === "zodiac-pisces") {
    return <Icon as={GiPisces} boxSize="2em" />;
  }
  return <Image boxSize="2em" />;
};

const AppIcon: React.FC<{ app: string }> = ({ app }) => {
  if (app === "apps-tiktok") {
    return <Icon as={FaTiktok} boxSize="2em" />;
  } else if (app === "apps-instagram") {
    return <Icon as={FaInstagram} boxSize="2em" />;
  } else if (app === "apps-pinterest") {
    return <Icon as={FaPinterest} boxSize="2em" />;
  } else if (app === "apps-spotify") {
    return <Icon as={FaSpotify} boxSize="2em" />;
  } else if (app === "apps-apple-music") {
    return <Icon as={FaApple} boxSize="2em" />;
  } else if (app === "apps-snapchat") {
    return <Icon as={FaSnapchatGhost} boxSize="2em" />;
  } else if (app === "apps-facebook") {
    return <Icon as={FaFacebook} boxSize="2em" />;
  } else if (app === "apps-x") {
    return <Icon as={FaXTwitter} boxSize="2em" />;
  } else if (app === "apps-youtube") {
    return <Icon as={FaYoutube} boxSize="2em" />;
  } else if (app === "apps-twitch") {
    return <Icon as={FaTwitch} boxSize="2em" />;
  } else if (app === "apps-vsco") {
    return <Icon as={TbBrandVsco} boxSize="2em" />;
  } else if (app === "apps-linkedin") {
    return <Icon as={FaLinkedin} boxSize="2em" />;
  }
  return <Image boxSize="2em" />;
};

export default PinIcon;
