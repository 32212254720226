import { Box, HStack, Link } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaBolt, FaMagnifyingGlass } from "react-icons/fa6";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { BACKDROP_BLUR, BACKGROUND_FADED } from "src/constants";
import { useCurrentUser } from "src/state/userQueries";
import { ActionButton } from "../Buttons";
import { HomePageHeader } from "./HomePageHeader";
import { ProfileButton } from "./ProfileButton";
import { ShareButton } from "./ShareButton";

const PRIVATE_ROUTES = ["/me", "/create/list", "/create/pin"];

export const Logo: React.FC<{
  height?: string;
  width?: string;
  linkDisabled?: boolean;
  color?: "white" | "black" | "gray";
  long?: boolean;
}> = ({ height, width, linkDisabled, color, long }) => {
  const InnerLogo = () => {
    return (
      // <Image
      //   // src={`/podiums-logo${long ? "-long" : ""}-${color ?? "white"}.png`}
      //   src="/podiums-logo-white.png"
      //   h={height}
      //   w={width}
      // />
      <HStack alignItems="end" spacing="3px">
        <Box
          height="18px"
          width="13px"
          bg={BACKGROUND_FADED}
          backdropBlur={BACKDROP_BLUR}
          backdropFilter="auto"
          borderRadius="5px"
        ></Box>
        <Box
          height={height}
          width="13px"
          bg={BACKGROUND_FADED}
          backdropBlur={BACKDROP_BLUR}
          backdropFilter="auto"
          borderRadius="5px"
        ></Box>
        <Box
          height="29px"
          width="13px"
          bg={BACKGROUND_FADED}
          backdropBlur={BACKDROP_BLUR}
          backdropFilter="auto"
          borderRadius="5px"
        ></Box>
      </HStack>
    );
  };
  return linkDisabled ? (
    <InnerLogo />
  ) : (
    <Link as={RouterLink} to="/">
      <InnerLogo />
    </Link>
  );
};

export const AppIconLogo: React.FC<{
  height?: string;
  width?: string;
  linkDisabled?: boolean;
  color?: "white" | "black" | "gray";
}> = ({ height, width, linkDisabled, color }) => {
  return (
    <Box
      boxSize="80px"
      justifyContent="center"
      alignItems="center"
      display="flex"
      borderRadius="15px"
      borderWidth="4px"
      borderColor={BACKGROUND_FADED}
      backdropBlur={BACKDROP_BLUR}
      backdropFilter="auto"
    >
      <Logo color="black" height="45px" />
    </Box>
  );
};

// const CreateListButton = () => {
//   return (
//     <Link as={RouterLink} to="/create/list">
//       <Icon
//         as={FaPlus}
//         aria-label="Create List"
//         size="2em"
//         fontSize="24px"
//         mb="-4px"
//         ml="6px"
//         mr="-4px"
//         _hover={{ cursor: "pointer" }}
//       />
//     </Link>
//   );
// };

export const ActivityButton = () => {
  return (
    <ActionButton icon={FaBolt} bg="white" as={RouterLink} to="/activity" />
  );
};

export const ExploreButton = () => {
  return (
    <ActionButton
      icon={FaMagnifyingGlass}
      bg="white"
      as={RouterLink}
      to="/explore"
    />
  );
};

export const Header: React.FC<{ hideSearch?: boolean; logoOnly?: boolean }> = ({
  hideSearch,
  logoOnly,
}) => {
  const navigate = useNavigate();
  const { data: user, isLoading } = useCurrentUser();
  const curPage = window.location.pathname;

  const maybeGoToSignUp = () => {
    if (curPage !== "/signup" && curPage !== "/") {
      console.log("navigating to /signup");
      navigate("/signup");
    }
  };

  useEffect(() => {
    if (isLoading) return;
    if (!user) {
      if (PRIVATE_ROUTES.includes(curPage)) {
        maybeGoToSignUp();
        return;
      } else {
        return;
      }
    }
    if (user.username.startsWith("!not-set-")) {
      // Go back to login to set username
      maybeGoToSignUp();
    }
    // else if (user.email && !user.loginMethod && !user.emailVerified) {
    // Go to verify email page
    // if (curPage !== "/verify-email" && curPage !== "/") {
    //   console.log("navigating to /verify-email");
    //   navigate("/verify-email");
    // }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  if (logoOnly) {
    return (
      <HStack px={3} py="1em" w="100%" justifyContent="center">
        <Logo height="40px" color="gray" />
      </HStack>
    );
  } else if (
    curPage === "/" ||
    !user ||
    isLoading ||
    user.username.startsWith("!not-set-") ||
    (user.email && !user.loginMethod && !user.emailVerified)
  ) {
    return <HomePageHeader />;
  } else {
    return (
      <HStack px={3} py="0.75em" justify="space-between" alignItems="center">
        <HStack>
          <Logo
            height="40px"
            long
            color={user.backgroundType === "white" ? "black" : "white"}
          />
        </HStack>
        <HStack
          alignItems="center"
          bg={BACKGROUND_FADED}
          backdropFilter="auto"
          backdropBlur={BACKDROP_BLUR}
          borderRadius="full"
          p="4px"
          spacing="6px"
          boxShadow="md"
        >
          <ShareButton username={user.username} />
          <ProfileButton user={user} />
        </HStack>
      </HStack>
    );
  }
};
