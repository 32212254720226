import { HStack, Icon, Text, VStack, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { FaLock } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { User } from "src/types/user";
import { PodiumsDialog } from "../PodiumsDialog";

export const UserStats: React.FC<{ user: User }> = ({ user }) => {
  const viewsDisclosure = useDisclosure();

  return (
    <VStack
      border={"2px solid black"}
      borderRadius="md"
      p="0.5em 0.75em"
      width="100%"
      alignItems="start"
      spacing={1}
    >
      <HStack justify="middle" spacing={2}>
        <Icon as={FaLock} boxSize="0.9em"></Icon>
        <Text fontWeight={600} fontSize="0.9em">
          Only I can see
        </Text>
      </HStack>
      <HStack justify="space-between" width="100%">
        <ProfileStat
          key="followers"
          number={user.followers.length}
          text="followers"
          to="/me/followers"
        />
        <ProfileStat
          key="following"
          number={user.following.length}
          text="following"
          to="/me/following"
        />
        <ProfileStat
          key="views"
          number={user.views ?? 0}
          text="views"
          onClick={viewsDisclosure.onOpen}
        />
      </HStack>
      <PodiumsDialog
        title="Profile Views"
        message={
          "You've had " +
          user.views +
          " view" +
          (user.views === 1 ? "" : "s") +
          " in the past 30 days."
        }
        disclosure={viewsDisclosure}
      />
    </VStack>
  );
};

const ProfileStat: React.FC<{
  number: number;
  text: string;
  onClick?: () => void;
  to?: string;
}> = ({ number, text, onClick, to }) => {
  return (
    <>
      <VStack
        spacing={0}
        cursor="pointer"
        _hover={{ bg: "topFive.gray.light" }}
        px="0.5em"
        borderRadius="md"
        transition="all 0.3s"
        onClick={onClick}
        as={!!to ? Link : undefined}
        to={to}
      >
        <Text fontSize="1.5em" fontWeight={600} mt="-3px" mb="-7px">
          {number}
        </Text>
        <Text>{text}</Text>
      </VStack>
    </>
  );
};
