import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

// const activeLabelStyles = {
//   transform: "scale(0.85) translateY(-24px)",
// };

const theme = extendTheme({
  // fonts: {
  //   heading: `'Kanit', sans-serif`,
  // },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode("#fff", "#fff")(props),
        // color: mode("#512B60", "#512B60")(props),
      },
    }),
  },
  colors: {
    topFive: {
      primary: "#191F22",
      primaryHover: "#BAAABF",
      secondary: "#2d83b5", // "#70BAE5", //"#fe938c"
      white: "#ffffff",
      gray: {
        light: "#d4d5d9",
        lightHover: "#bec0c5",
        mid: "#bfbfbf",
        dark: "#4F5762",
      },
      black: "#191F22",
    },
  },
  components: {
    Form: {
      // baseStyle: {
      //   container: {
      //     _focusWithin: {
      //       label: {
      //         ...activeLabelStyles,
      //       },
      //     },
      //     "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
      //       {
      //         ...activeLabelStyles,
      //       },
      //     label: {
      //       top: 0,
      //       left: 0,
      //       zIndex: 2,
      //       position: "absolute",
      //       backgroundColor: "white",
      //       borderRadius: "4px",
      //       pointerEvents: "none",
      //       mx: 3,
      //       px: 1,
      //       my: 2,
      //       transformOrigin: "left top",
      //     },
      //     _readOnly: {
      //       label: {
      //         color: "white",
      //       },
      //     },
      //   },
      // },
    },
    Button: {
      variants: {
        light: {
          bg: "#fff",
          color: "topFive.primary",
          _hover: { bg: "topFive.gray.lightHover" },
        },
        dark: {
          bg: "topFive.primary",
          color: "white",
          _hover: { bg: "topFive.primaryHover" },
        },
        outline: {
          bg: "transparent",
          color: "topFive.primary",
          border: "2px solid",
          borderColor: "topFive.primary",
          _hover: { bg: "topFive.primary", color: "white" },
        },
      },
    },
    Input: {
      variants: {
        filled: {
          field: {
            backgroundColor: "#fff",
            paddingLeft: "12px",
            _hover: {
              backgroundColor: "#fff",
            },
            // _hover: {
            //   backgroundColor: "#fff !important",
            // },
            // _active: {
            //   backgroundColor: "#fff !important",
            // },
            _focusVisible: {
              backgroundColor: "#fff",
              border: "none",
              borderColor: "none",
            },
          },
        },
      },
      defaultProps: {
        variant: "filled",
      },
    },
  },
});

export default theme;
