import React from "react";
import ListOfUsers from "src/components/User/ListOfUsers";
import { useMyFollowers, useMyFollowing } from "src/state/userQueries";

export const MyFollowers: React.FC = () => {
  const query = useMyFollowers();
  return <ListOfUsers query={query} />;
};

export const MyFollowing: React.FC = () => {
  const query = useMyFollowing();
  return <ListOfUsers query={query} />;
};
