import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { errorToast } from "src/utils";

export const sendText = (phoneNumber: string, toast: any) => {
  const appVerifier = (window as any).recaptchaVerifier;
  const auth = getAuth();
  signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    .then((confirmationResult: any) => {
      (window as any).confirmationResult = confirmationResult;
      console.log("SMS sent");
    })
    .catch((error: any) => {
      if (process.env.REACT_APP_DEBUG) {
        console.error("Error sending SMS:", error);
      }
      //   TODO reset the captcha
      //   (window as any).recaptchaVerifier.render().then(function (widgetId) {

      //     grecaptcha.reset(widgetId);
      //   });
      errorToast("Error sending SMS", toast);
    });
};
export const prettifyPhoneNumber = (phoneNumber: string) => {
  // Remove all non-digits from the phone number
  phoneNumber = phoneNumber.replace(/\D/g, "");

  // Check if the phone number length matches a certain format
  var formattedPhoneNumber;
  if (phoneNumber.length === 10) {
    // Format as (123) 456-7890
    formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "+1 ($1) $2-$3"
    );
  } else if (phoneNumber.length === 11 && phoneNumber.startsWith("1")) {
    // Format as +1 (123) 456-7890
    formattedPhoneNumber = phoneNumber.replace(
      /(\d{1})(\d{3})(\d{3})(\d{4})/,
      "+$1 ($2) $3-$4"
    );
  } else {
    // No matching format, return the original input
    formattedPhoneNumber = phoneNumber;
  }

  return formattedPhoneNumber;
};

export const minifyPhoneNumber = (phoneNumber: string) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the cleaned number is valid
  const isValid = /^[0-9]{10,15}$/.test(cleaned);
  if (!isValid) {
    console.error("Invalid phone number format");
    return "";
  }

  // Format to the desired format
  const countryCode =
    cleaned.length > 10
      ? "+" + cleaned.substring(0, cleaned.length - 10)
      : "+1";
  const areaCode = cleaned.substring(cleaned.length - 10, cleaned.length - 7);
  const firstThree = cleaned.substring(cleaned.length - 7, cleaned.length - 4);
  const lastFour = cleaned.substring(cleaned.length - 4);
  return `${countryCode}${areaCode}${firstThree}${lastFour}`;
};
