import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react";
import { FaPencil } from "react-icons/fa6";

export const EditPinOptions: React.FC<{
  onToggleEdit: () => void;
}> = ({ onToggleEdit }) => {
  return (
    <Menu>
      <MenuButton
        aria-label="Options"
        color="black"
        fontWeight="800"
        borderRadius="full"
      >
        •••
      </MenuButton>
      <MenuList boxShadow="0px 8px 20px 0px rgba(0,0,0,0.2)">
        <MenuItem icon={<FaPencil />} onClick={onToggleEdit}>
          Edit pins
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
