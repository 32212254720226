import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaCheck, FaCircleCheck } from "react-icons/fa6";
import { TbColumns1, TbColumns2 } from "react-icons/tb";
import { AllBackgroundTypes, SanitizedOtherUser } from "src/types/user";
import { ActionButton } from "../Buttons";

interface ChangeBackgroundButtonProps {
  user: SanitizedOtherUser;
  disclosure: { isOpen: boolean; onOpen: () => void; onClose: () => void };
  updateThemeLocal?: (theme: string) => void;
  updateLayoutLocal?: (oneColumn: boolean) => void;
  onSaveTheme?: () => void;
}

export const ChangeThemeModal: React.FC<ChangeBackgroundButtonProps> = ({
  user,
  disclosure: { isOpen, onClose },
  updateThemeLocal,
  updateLayoutLocal,
  onSaveTheme,
}) => {
  const [localTheme, setLocalTheme] = useState<string>(
    user.backgroundType ?? ""
  );
  const [isOneColumn, setIsOneColumn] = useState<boolean>(
    user.listLayout.length === 1
  );
  const themeHasChanged = localTheme !== user.backgroundType;
  const layoutHasChanged = user.listLayout.length !== (isOneColumn ? 1 : 2);
  const handleBackgroundChange = async () => {
    if (themeHasChanged || layoutHasChanged) {
      onSaveTheme?.();
      setLocalTheme(user.backgroundType ?? "");
      setIsOneColumn(user.listLayout.length === 1);
      onClose();
    }
  };

  const nonImageBackgrounds = AllBackgroundTypes.filter(
    (t) => !t.includes("image")
  );
  const imageBackgrounds = AllBackgroundTypes.filter((t) =>
    t.includes("image")
  );

  useEffect(() => {
    if (user.backgroundType !== localTheme) {
      setLocalTheme(user.backgroundType ?? "");
    }
    if ((user.listLayout.length === 1) !== isOneColumn) {
      setIsOneColumn(user.listLayout.length === 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.backgroundType, user.listLayout]);

  const BackgroundGridButton: React.FC<{
    type: string;
    [key: string]: any;
  }> = ({ type, ...rest }) => {
    const isImage = type.includes("image");
    return (
      <GridItem
        key={type}
        className={"bg-" + type}
        backgroundSize={isImage ? "cover" : "auto"}
        backgroundRepeat={isImage ? "no-repeat" : "repeat"}
        backgroundPosition={
          isImage ? { base: "center", md: "center top" } : "auto"
        }
        borderRadius={isImage ? "2xl" : "full"}
        borderWidth="1px"
        borderColor={type === "white" ? "gray.400" : "none"}
        boxSizing="border-box"
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...rest}
      >
        <Button
          height={isImage ? "80px" : "58px"}
          bg={localTheme === type ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0)"}
          onClick={() => {
            setLocalTheme(type);
            updateThemeLocal?.(type);
          }}
          w="100%"
          borderRadius={isImage ? "2xl" : "full"}
          _hover={{
            bg: "rgba(0,0,0,0.1)",
          }}
        >
          {(localTheme === type ||
            (localTheme === undefined && type === "party-gradient")) && (
            <Icon as={FaCircleCheck} color="white" boxSize="1.5em" />
          )}
        </Button>
      </GridItem>
    );
  };

  const ColumnButtons = () => (
    <HStack w="100%" display="flex" pb="1em" spacing={3}>
      <Button
        aria-label="1 column"
        flex={1}
        py="2em"
        display="flex"
        flexDirection="column"
        borderWidth="2px"
        borderColor="gray.600"
        bg={isOneColumn ? "gray.300" : "white"}
        color="gray.700"
        onClick={() => {
          updateLayoutLocal?.(true);
          setIsOneColumn(true);
        }}
      >
        <Icon as={TbColumns1} boxSize={6} mb="5px" />
        <Text>1 column</Text>
      </Button>
      <Button
        aria-label="2 columns"
        flex={1}
        py="2em"
        display="flex"
        flexDirection="column"
        borderWidth="2px"
        borderColor="gray.600"
        bg={isOneColumn ? "white" : "gray.300"}
        color="gray.700"
        onClick={() => {
          updateLayoutLocal?.(false);
          setIsOneColumn(false);
        }}
      >
        <Icon as={TbColumns2} boxSize={6} mb="5px" />
        <Text>2 columns</Text>
      </Button>
    </HStack>
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
        isCentered={false}
        size="xl"
      >
        <ModalOverlay bg="blackAlpha.200" backdropFilter="blur(1px)" />
        <ModalContent
          position="fixed"
          borderRadius="xl"
          borderBottomRadius={0}
          bottom="0"
          margin="0"
        >
          <ModalHeader p="0.5em 1em 0em 1em">
            <HStack justify="space-between">
              <Heading as={"h3"} fontSize="18px" fontWeight={500}>
                Columns
              </Heading>
              {themeHasChanged || layoutHasChanged ? (
                <ActionButton
                  icon={FaCheck}
                  onClick={handleBackgroundChange}
                  padding={2}
                  size="1.5em"
                  color="black"
                  border={"2px solid black"}
                />
              ) : (
                <ModalCloseButton
                  size="1.5em"
                  pos="relative"
                  my="5px"
                  top={0}
                  right={0}
                />
              )}
            </HStack>
          </ModalHeader>
          <ModalBody px="1em" pb="1.25em">
            <ColumnButtons />
            <Heading as={"h3"} fontSize="18px" fontWeight={500}>
              Background
            </Heading>
            <Box
              color="topFive.secondary"
              mt={3}
              mx={-4}
              px={4}
              pos="relative"
              overflowX="scroll"
              whiteSpace="nowrap"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none", // for Internet Explorer and Edge
                scrollbarWidth: "none", // for Firefox
              }}
            >
              <Flex gap="0.5em">
                {nonImageBackgrounds.map((type) => {
                  return (
                    <Box minW="fit-content">
                      <Box
                        key={type}
                        height="40px"
                        className={"bg-" + type}
                        onClick={() => {
                          setLocalTheme(type);
                          updateThemeLocal?.(type);
                        }}
                        w="42px"
                        borderRadius="full"
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderWidth="1px"
                        borderColor={type === "white" ? "gray.400" : "none"}
                      >
                        {(localTheme === type ||
                          (localTheme === undefined &&
                            type === "party-gradient")) && (
                          <Icon
                            as={FaCircleCheck}
                            color="white"
                            boxSize="1.25em"
                            flex={1}
                          />
                        )}
                      </Box>
                    </Box>
                  );
                })}
                <Box minW={2}></Box>
              </Flex>
            </Box>
            {/* <Grid templateColumns="repeat(5, 1fr)" gap={3}>
              {nonImageBackgrounds.map((type) => {
                return <BackgroundGridButton key={type} type={type} />;
              })} */}
            {/* </Grid> */}
            <Grid templateColumns="repeat(4, 1fr)" gap={3} mt={3}>
              {imageBackgrounds.map((type) => {
                return <BackgroundGridButton key={type} type={type} />;
              })}
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
