import {
  Box,
  Card,
  Center,
  Collapse,
  Fade,
  HStack,
  Heading,
  Icon,
  Slide,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  FaCheck,
  FaChevronLeft,
  FaEye,
  FaEyeSlash,
  FaRegCircleXmark,
  FaSpotify,
  FaTrashCan,
} from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { ProfilePicture } from "src/components/User/UserProfileHeader";
import {
  BACKDROP_BLUR,
  BACKGROUND_FADED,
  BACKGROUND_HIDDEN,
  TALL_IMAGE_LISTS,
} from "src/constants";
import {
  useDeleteList,
  useRefreshList,
  useUpdateList,
} from "src/state/userQueries";
import { Pin } from "src/types/pin";
import { SanitizedOtherUser } from "src/types/user";
import { listItemsChanged } from "src/utils";
import { ListItem as ListItemType, UserList } from "../../../types/list";
import { ActionButton, BackButton } from "../../Buttons";
import ListDialogs from "../Utils/ListDialogs";
import { UpdateSpotifyButton } from "../Utils/ListIcon";
import { SearchField } from "../Utils/Search";
import { SortableList } from "../Utils/SortableList";
import ListItem from "../View/ListItem";

interface ListProps {
  user: SanitizedOtherUser;
  list: UserList;
  editMode: string;
  setEditMode: (id: string) => void;
  fromScratch?: boolean;
}

const EditList: React.FC<ListProps> = ({
  user,
  list,
  editMode,
  setEditMode,
  fromScratch,
}) => {
  const [listItems, setListItems] = useState(list.items);
  const [startAnimating, setStartAnimating] = useState(false);
  const [isHidden, setIsHidden] = useState(list.hidden ?? false);

  const toast = useToast();
  const navigate = useNavigate();
  const updateList = useUpdateList();

  const deleteDialogDisclosure = useDisclosure();
  const spotifyDisclosure = useDisclosure();
  const discardChangesDisclosure = useDisclosure();
  const onRefresh = useListRefresh(
    list.slug,
    () => {
      setStartAnimating(false);
      setTimeout(() => {
        setEditMode("");
        navigate(`/me/${list.slug}`);
      }, 300);
    },
    list.integrationProviderSlug
  );
  const onDelete = useDeleteListToast(list, () => setEditMode(""));
  const onToggleHidden = useListToggleHidden(list);
  const onBack = (link: string) => {
    setStartAnimating(false);
    setTimeout(() => {
      setEditMode("");
      navigate(link);
    }, 300);
  };

  const onMaybeDiscard = (e: any, link: string) => {
    e.preventDefault();
    if (!listItemsChanged(list.items, listItems)) {
      onBack(link);
    } else {
      discardChangesDisclosure.onOpen();
    }
  };

  const addToList = (item: ListItemType | Pin) => {
    if (listItems.find((i) => i.title === item.title)) {
      toast({
        title: "This item is already in your list!",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setListItems([...listItems, item as ListItemType]);
  };

  const toggleEditModeAndSave = async () => {
    if (setEditMode) {
      if (editMode !== "") {
        if (!listItemsChanged(list.items, listItems)) {
          setStartAnimating(false);
          setTimeout(() => {
            setEditMode("");
          }, 300);
          return;
        } else {
          const updatePromise = updateList.mutateAsync({
            slug: list.slug,
            items: listItems,
          });
          toast.promise(updatePromise, {
            success: (res) => {
              setStartAnimating(false);
              let isThirdList = false;
              if (res.status === 201 && user.lists.length === 3) {
                isThirdList = true;
              }
              setTimeout(() => {
                setEditMode("");
                navigate(
                  `/${user.username}/${list.slug}${isThirdList ? "?third" : ""}`
                );
              }, 300);
              return {
                title: "List saved!",
                duration: 2000,
                isClosable: true,
              };
            },
            error: (e) => {
              return { title: "Error", description: "Error saving list" };
            },
            loading: { title: "Saving..." },
          });
        }
      } else {
        setEditMode(list.slug);
      }
    }
  };

  const removeFromList = (i: ListItemType | Pin) => {
    const item = i as ListItemType;
    setListItems(
      listItems.filter((result) => {
        if (result._id && item._id) {
          return result._id !== item._id;
        } else {
          return (
            result.title + result.subtitle + result.imageURI !==
            item.title + item.subtitle + item.imageURI
          );
        }
      })
    );
  };

  useEffect(() => {
    if (editMode) {
      setTimeout(() => setStartAnimating(true), 1);
    }
  }, [editMode]);

  return (
    <>
      <Center
        px={startAnimating ? "0" : "1em"}
        pb={startAnimating ? "0" : "1.5em"}
        width="100%"
        mt={startAnimating ? "-5.5em" : 0}
        transition="all 0.3s ease-in-out"
      >
        <Card
          p="0.5em 1.25em 1em 1.25em"
          pt={
            startAnimating
              ? isHidden
                ? "4.1em"
                : "2em"
              : isHidden
              ? "2.5em"
              : "0.5em"
          }
          borderRadius={startAnimating ? 0 : "xl"}
          width="100%"
          maxWidth={startAnimating ? "100%" : "450px"}
          minHeight="38em"
          minH={startAnimating ? "103vh" : "0"}
          overflow="hidden"
          transition="all 0.3s ease-in-out"
          bg={startAnimating ? "white" : BACKGROUND_FADED}
          backdropFilter="auto"
          backdropBlur={BACKDROP_BLUR}
        >
          <Collapse in={isHidden}>
            <Box
              bg={BACKGROUND_HIDDEN}
              p="1.25em 1em 0.5em 1em"
              pos="absolute"
              left={0}
              top={startAnimating ? "0.75em" : "-0.5em"}
              width="100%"
              transition="all 0.3s ease-in-out"
            >
              <Icon as={FaEyeSlash} mr="7px" mb="-2px" />
              Hidden on profile
            </Box>
          </Collapse>
          <HStack spacing={3} pt={2} pb={1}>
            <Slide
              in={!editMode}
              unmountOnExit
              direction="right"
              style={{
                left: "1em",
                top: editMode !== "" && isHidden ? "3.2em" : "1.1em",
                transition: "top 0.3s ease-in-out",
              }}
            >
              <BackButton
                width="auto"
                onClick={() => {
                  setStartAnimating(false);
                  setTimeout(() => {
                    setEditMode("");
                  }, 300);
                }}
                fontSize="1.5em"
              />
            </Slide>
            <HStack ml="-0.5em">
              <ActionButton
                icon={FaChevronLeft}
                width="auto"
                aria-label="Back to list"
                onClick={(e: any) => {
                  if (fromScratch) onMaybeDiscard(e, "/create/list");
                  else onMaybeDiscard(e, `/${user.username}/${list.slug}`);
                }}
                as="a"
                href={
                  fromScratch
                    ? "/create/list"
                    : `/${user.username}/${list.slug}`
                }
              />
              <ProfilePicture src={user.profilePicture} size={2} disableClick />
              <VStack align="start" spacing={0}>
                <Heading fontSize="0.75em" fontWeight={500}>
                  @{user.username}
                </Heading>
                <Heading fontSize="lg">{list.name}</Heading>
              </VStack>
            </HStack>
            <Fade in={startAnimating} unmountOnExit>
              <HStack
                spacing={3}
                position="absolute"
                right="1.25em"
                top={isHidden ? "4.6em" : "2.25em"}
                transition="all 0.3s ease-in-out"
              >
                {list.integrationProviderSlug === "spotify" &&
                  (list.updateAutomatically ? (
                    <UpdateSpotifyButton onClick={spotifyDisclosure.onOpen} />
                  ) : (
                    <Icon
                      as={FaSpotify}
                      boxSize="2em"
                      onClick={spotifyDisclosure.onOpen}
                      cursor="pointer"
                    />
                  ))}
                <ActionButton
                  icon={FaTrashCan}
                  onClick={deleteDialogDisclosure.onOpen}
                  color="black"
                  border={"2px solid black"}
                  padding={2}
                  transition="all 0.3s ease-in-out"
                />
                {!fromScratch && (
                  // because there is no list in DB yet
                  <ActionButton
                    icon={isHidden ? FaEye : FaEyeSlash}
                    onClick={() => setIsHidden(onToggleHidden(!isHidden))}
                    color="black"
                    border={"2px solid black"}
                    padding={2}
                    transition="all 0.3s ease-in-out"
                  />
                )}
                <ActionButton
                  icon={FaCheck}
                  onClick={toggleEditModeAndSave}
                  padding={2}
                  color="black"
                  border={"2px solid black"}
                />
              </HStack>
            </Fade>
          </HStack>
          <Collapse in={startAnimating} startingHeight={0}>
            <SearchField
              handleAddItem={addToList}
              handleRemoveItem={removeFromList}
              listName={list.name}
              modalStartsOpen={fromScratch}
              existingItems={listItems}
            />
          </Collapse>
          {/* {listItems.length > 5 && (
            <Box
              pos="absolute"
              bg="topFive.gray.light"
              width="100%"
              height="100%"
              top={isHidden ? "41em" : "38.5em"}
              left="0em"
              transition="all 0.3s ease-in-out"
            ></Box>
          )} */}
          <Box
            mt={startAnimating ? 0 : "0.5em"}
            transition="all 0.3s ease-in-out"
          >
            <SortableList
              items={listItems.map(
                (item) => item._id ?? item.title + item.imageURI
              )}
              setItems={setListItems}
            >
              {listItems.map((item, i) => (
                <>
                  {/* {i === 5 && (
                    <Center
                      pt={startAnimating ? "0.5em" : "2em"}
                      transition="all 0.3s ease-in-out"
                    >
                      <ItemsHiddenButton
                        onOpen={hiddenDialogDisclosure.onOpen}
                      />
                    </Center>
                  )} */}
                  <ListItem
                    key={item._id ?? item.title + item.imageURI}
                    rank={i + 1}
                    item={item}
                    editMode={editMode}
                    allowDrag={true}
                    tallImage={TALL_IMAGE_LISTS.includes(list.slug)}
                    editAction={{
                      icon: FaRegCircleXmark,
                      action: () => {
                        removeFromList(item);
                      },
                    }}
                    startAnimating={startAnimating}
                  />
                </>
              ))}
            </SortableList>
          </Box>
        </Card>
      </Center>
      <ListDialogs
        onMyProfile
        list={list}
        spotifyDisclosure={spotifyDisclosure}
        // refreshDisclosure={refreshDialogDisclosure}
        deleteDisclosure={deleteDialogDisclosure}
        discardDisclosure={discardChangesDisclosure}
        onDelete={onDelete}
        onRefresh={onRefresh}
        onDiscardChanges={() =>
          onBack(
            fromScratch ? "/create/list" : `/${user.username}/${list.slug}`
          )
        }
      />
    </>
  );
};

export const useDeleteListToast = (
  list: UserList,
  closeEditMode: () => void
) => {
  const navigate = useNavigate();
  const toast = useToast();
  const deleteList = useDeleteList(list.slug);
  return () => {
    const deletePromise = deleteList.mutateAsync();
    toast.promise(deletePromise, {
      success: (res) => {
        closeEditMode();
        navigate("/me");
        return { title: "List deleted" };
      },
      error: { title: "Error", description: "Error deleting list" },
      loading: { title: "Deleting..." },
    });
  };
};

export const useListToggleHidden = (list: UserList) => {
  const toast = useToast();
  const updateList = useUpdateList();
  return (hidden: boolean) => {
    // if (list.items.length < 5 && !hidden) {
    //   toast({
    //     title: "Oops",
    //     description: "Add 5 items before making your list public",
    //     status: "warning",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   return true;
    // }
    const hidePromise = updateList.mutateAsync({
      slug: list.slug,
      hidden: hidden,
    });
    toast.promise(hidePromise, {
      success: (res) => {
        if (!res) {
          return {
            title: `Error ${hidden ? "hiding" : "showing"} list`,
            status: "error",
          };
        }
        return { title: `List ${hidden ? "hidden" : "shown"} on profile` };
      },
      error: (err) => {
        return {
          title: "Error",
          description: `Error ${hidden ? "hiding" : "showing"} list`,
        };
      },
      loading: { title: "Loading..." },
    });
    return hidden;
  };
};

export const useListRefresh = (
  listSlug: string,
  closeEditMode: () => void,
  integrationProviderSlug?: string
) => {
  const toast = useToast();

  const refreshIntegration = useRefreshList(
    listSlug,
    integrationProviderSlug ?? ""
  );
  if (!integrationProviderSlug) {
    return () => {};
  }
  return () => {
    if (!integrationProviderSlug) {
      return;
    }
    const newListPromise = refreshIntegration.mutateAsync();
    toast.promise(newListPromise, {
      success: (res: any) => {
        closeEditMode();
        return { title: "List refreshed!", duration: 2000, isClosable: true };
      },
      error: { title: "Error", description: "Error refreshing list" },
      loading: { title: "Loading..." },
    });
  };
};

export default EditList;
