import { Icon, Image } from "@chakra-ui/react";
import React from "react";
import { FaUser } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { User } from "src/types/user";

interface ProfileButtonProps {
  user: User;
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({ user }) => {
  return (
    <Link to="/me">
      {!user || !user.profilePicture ? (
        <Icon
          as={FaUser}
          fontSize="24px"
          boxSize={"1.5em"}
          padding="0.25em"
          mb="-0.25em"
          mr="2px"
          ml="-4px"
          _hover={{ cursor: "pointer" }}
        />
      ) : (
        <Image
          bg="gray.300"
          boxSize={"2em"}
          borderRadius="full"
          overflow="hidden"
          src={user.profilePicture}
        />
      )}
    </Link>
  );
};
