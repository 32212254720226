import { Button, Input, Text, VStack, useToast } from "@chakra-ui/react";
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { errorToast } from "../../utils";
import { BackButton } from "../Buttons";
import { prettifyPhoneNumber } from "./verifyPhoneUtils";

const VerifyPhone: React.FC<{
  phoneNumber: string;
  setFirebaseToken: (token: string) => void;
  onBack: () => void;
  usePassword?: () => void;
}> = ({ phoneNumber, usePassword, setFirebaseToken, onBack }) => {
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [stepNumber, setStepNumber] = useState(0);
  const [textsAllowed, setTextsAllowed] = useState(true);
  const [sendingCode, setSendingCode] = useState(false);

  const toast = useToast();

  const sendText = () => {
    setSendingCode(true);
    const auth = getAuth();
    const appVerifier = (window as any).recaptchaVerifier;
    signInWithPhoneNumber(auth, prettifyPhoneNumber(phoneNumber), appVerifier)
      .then((confirmationResult) => {
        (window as any).confirmationResult = confirmationResult;
        console.log("SMS sent");
        setSendingCode(false);
        setStepNumber(1);
      })
      .catch((error) => {
        console.error("Error sending SMS:", error);
        errorToast(
          "Error sending SMS, please try logging in with a username and password.",
          toast,
          "top"
        );
        setSendingCode(false);
      });
  };

  useEffect(() => {
    // Setup recaptcha verifier
    if (!process.env.REACT_APP_DEBUG) {
      setTextsAllowed(false);
      const auth = getAuth();
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "sign-in-button",
        {
          size: "invisible", //"invisible",
          callback: (response: any) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log("reCAPTCHA solved");
            sendText();
          },
          expiredCallback: () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            console.log("reCAPTCHA expired");
            errorToast("Please solve the reCAPTCHA", toast, "top");
          },
        }
      );
      (window as any).recaptchaVerifier.render().then((widgetId: any) => {
        console.log("rendered");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (stepNumber === 0) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log("submitting locally but check jic");
          errorToast(
            "Error sending code, please try logging in with a username and password.",
            toast,
            "top"
          );
          setSendingCode(false);
          if (!textsAllowed) {
            errorToast("Please solve the reCAPTCHA", toast, "top");
            return;
          } else {
            console.log("submitting locally but check jic");
            errorToast(
              "Error sending code, please try logging in with a username and password.",
              toast,
              "top"
            );
            setSendingCode(false);
          }
        }}
        style={{ width: "100%" }}
      >
        <VStack spacing={4} w="100%">
          <BackButton text="Back" onClick={onBack} />
          <Text>
            To {usePassword ? "log you in" : "sign you up"}, we'll send a text
            to
          </Text>
          <Text mt="-10px" mb="10px">
            {prettifyPhoneNumber(phoneNumber)}.
          </Text>
          {usePassword && (
            <Button
              w="100%"
              variant="light"
              onClick={usePassword}
              borderRadius="full"
            >
              Use password instead
            </Button>
          )}
          <Button
            mt={-2}
            w="100%"
            variant="dark"
            type="submit"
            id="sign-in-button"
            isLoading={sendingCode}
            borderRadius="full"
          >
            Send Code
          </Button>
        </VStack>
      </form>
    );
  } else if (stepNumber === 1) {
    const handleSubmit = (code: string) => {
      setVerifyingCode(true);
      if (!code || code.length !== 6) {
        errorToast("Please enter the 6-digit code", toast, "top");
        return;
      }
      try {
        (window as any).confirmationResult
          .confirm(code)
          .then(async (result: any) => {
            const accessToken = result.user.accessToken;
            setFirebaseToken(accessToken);
          })
          .catch((error: any) => {
            console.error("Error signing in:", error);
            errorToast("Incorrect code", toast, "top");
            setVerifyingCode(false);
          });
      } catch (error) {
        console.error("Error confirming code:", error);
        errorToast(
          "Please try logging in with an email and password.",
          toast,
          "top"
        );
        setVerifyingCode(false);
      }
    };
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(inputCode);
        }}
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <VStack spacing={2} w="100%" maxW="300px">
          <Text>We sent a text to {prettifyPhoneNumber(phoneNumber)}.</Text>
          <Input
            variant="filled"
            autoComplete="one-time-code"
            placeholder="Enter 6-digit code"
            value={inputCode}
            type="text"
            inputMode="numeric"
            maxLength={6}
            pattern="\d{6}"
            autoFocus
            onChange={(e) => {
              const { value } = e.target;
              // Ensure only digits are entered and limit input to 6 characters
              if (/^\d*$/.test(value) && value.length <= 6) {
                setInputCode(value);
              }
            }}
          />
          <Button
            w="100%"
            variant="dark"
            type="submit"
            isLoading={verifyingCode}
            isDisabled={inputCode.length !== 6}
            borderRadius="full"
          >
            Continue
          </Button>
        </VStack>
      </form>
    );
  } else {
    throw new Error("Invalid step number");
  }
};

const Testing = () => {
  const [inputCode, setInputCode] = useState("");
  return (
    <form
      onSubmit={(e) => {
        alert("submitting code from form: " + inputCode);
        e.preventDefault();
      }}
    >
      <Input
        variant="filled"
        autoComplete="one-time-code"
        placeholder="Enter 6-digit code"
        value={inputCode}
        type="text"
        inputMode="numeric"
        maxLength={6}
        pattern="\d{6}"
        autoFocus
        onChange={(e) => {
          const { value } = e.target;
          // Ensure only digits are entered and limit input to 6 characters
          if (/^\d*$/.test(value)) {
            if (value.length < 6) {
              setInputCode(value);
            } else if (value.length === 6) {
              setInputCode(value);
              alert("submitting code from onchange: " + value);
            }
          }
        }}
      />
      <Button
        w="100%"
        variant="dark"
        // type="submit"
        isDisabled={inputCode.length !== 6}
        borderRadius="full"
        onClick={() => {
          alert("submitting code from button: " + inputCode);
        }}
      >
        Submit
      </Button>
    </form>
  );
};

export default VerifyPhone;
