import {
  Button,
  HStack,
  Icon,
  Link,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaGoogle, FaMobileScreen, FaSpotify, FaUser } from "react-icons/fa6";
import { Link as RouterLink } from "react-router-dom";
import { PodiumsDialog } from "../PodiumsDialog";

const DialogContent: React.FC<{ provider: string }> = ({ provider }) => {
  return (
    <VStack alignItems="start" spacing={4} mt="-1em">
      <Text>
        Sign in with {provider} is not supported on the Instagram browser. You
        can either:
      </Text>
      <VStack bg="gray.100" padding={4} borderRadius="lg" spacing={4}>
        <HStack spacing={4}>
          <Icon boxSize="1.3em" as={FaMobileScreen} />
          <Text>
            Go to{" "}
            <Link
              as={RouterLink}
              to="https://podiu.ms"
              textDecoration="underline"
            >
              podiu.ms
            </Link>{" "}
            in your phone's browser (like Safari).
          </Text>
        </HStack>
        <HStack spacing={4}>
          <Icon boxSize="1.3em" as={FaUser} />
          <Text>Or, you can use a different login method.</Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

export const GoogleLoginButton = () => {
  var ua = navigator.userAgent || navigator.vendor || (window as any).opera;
  var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
  const disclosure = useDisclosure();
  return (
    <>
      <Button
        w="100%"
        leftIcon={<Icon as={FaGoogle} />}
        variant="light"
        as={isInstagram ? undefined : RouterLink}
        to={process.env.REACT_APP_SERVER_URL + "/auth/google"}
        onClick={isInstagram ? disclosure.onOpen : undefined}
        borderRadius="full"
      >
        Sign in with Google
      </Button>
      <PodiumsDialog
        disclosure={disclosure}
        title="Oops..."
        content={<DialogContent provider="Google" />}
        cancelText="OK"
      />
    </>
  );
};

export const SpotifyLoginButton = () => {
  var ua = navigator.userAgent || navigator.vendor || (window as any).opera;
  var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
  const disclosure = useDisclosure();
  return (
    <>
      <Button
        w="100%"
        leftIcon={<Icon as={FaSpotify} />}
        color="#ffffff"
        backgroundColor="#1DB954"
        borderRadius="full"
        border="2px solid #1DB954"
        _hover={{ backgroundColor: "#1ED760", borderColor: "#1ED760" }}
        as={isInstagram ? undefined : RouterLink}
        to={process.env.REACT_APP_SERVER_URL + "/auth/spotify"}
        onClick={isInstagram ? disclosure.onOpen : undefined}
      >
        Sign in with Spotify
      </Button>
      <PodiumsDialog
        disclosure={disclosure}
        title="Oops..."
        content={<DialogContent provider="Spotify" />}
        cancelText="OK"
      />
    </>
  );
};
