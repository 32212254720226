import {
  Button,
  Center,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Header } from "src/components/Header/Header";
import PodiumsCard from "src/components/PodiumsCard";
import { ShimmerUserSearchRow } from "src/components/Shimmer";
import GradientBackground from "src/components/User/UserBackground";
import UserSearchRow from "src/components/User/UserSearchRow";
import { useGetAllUsers } from "src/state/userQueries";
import { SanitizedOtherUser } from "src/types/user";
import { compareSearchResults } from "../utils";

const RECENT_SEARCHES_KEY = "recentSearches";

const getRecentSearches = (): string[] => {
  const recentSearchesString = localStorage.getItem(RECENT_SEARCHES_KEY);
  return recentSearchesString ? JSON.parse(recentSearchesString) : [];
};

const addRecentSearch = (username: string) => {
  const recentSearches = getRecentSearches();
  const updatedRecentSearches = [
    username,
    ...recentSearches.filter((search) => search !== username),
  ].slice(0, 5);
  localStorage.setItem(
    RECENT_SEARCHES_KEY,
    JSON.stringify(updatedRecentSearches)
  );
};

const removeRecentSearch = (username: string) => {
  const recentSearches = getRecentSearches();
  const updatedRecentSearches = recentSearches
    .filter((search) => search !== username)
    .slice(0, 5);
  localStorage.setItem(
    RECENT_SEARCHES_KEY,
    JSON.stringify(updatedRecentSearches)
  );
};

const ExplorePage: React.FC = () => {
  const [searchInput, setSearchInput] = useState("");
  // TODO: eventually, filter users server-side
  // const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([] as SanitizedOtherUser[]);
  const [recentSearches, setRecentSearches] = useState([] as string[]);

  useEffect(() => {
    // Retrieve recent searches from local storage
    const storedRecentSearches = getRecentSearches();
    setRecentSearches(storedRecentSearches);
  }, []);

  const { data, isError, isLoading } = useGetAllUsers();
  const navigate = useNavigate();

  useEffect(() => {
    if (data != null) {
      const queryParams = new URLSearchParams();
      if (searchInput) {
        queryParams.set("q", searchInput);
        setUsers(
          data.filter(
            (user) =>
              user.username.toLowerCase().includes(searchInput.toLowerCase()) ||
              user.firstName
                ?.toLowerCase()
                .includes(searchInput.toLowerCase()) ||
              user.lastName?.toLowerCase().includes(searchInput.toLowerCase())
          )
        );
      } else {
        setUsers(data);
        queryParams.delete("q");
      }
      navigate(`?${queryParams.toString()}`);
    }
  }, [data, searchInput]);

  const getUserRows = () => {
    const onSelectUser = (username: string) => {
      addRecentSearch(username);
      navigate(`/${username}`, {
        state: { backURL: window.location.href },
      });
    };

    const onRemoveUser = (username: string) => {
      removeRecentSearch(username);
      setRecentSearches(recentSearches.filter((u) => u !== username));
    };

    if (isLoading) {
      return (
        <>
          {[...Array(10)].map((_, index) => (
            <ShimmerUserSearchRow key={index} />
          ))}
        </>
      );
    } else if (isError || !users || users.length === 0) {
      return (
        <>
          <PodiumsCard>
            <Heading size="4xl">😥</Heading>
            <Heading>No users</Heading>
          </PodiumsCard>
        </>
      );
    } else {
      return users
        .sort((a, b) => compareSearchResults(a, b, recentSearches))
        .map((u) => (
          <UserSearchRow
            user={u}
            key={u.username}
            onClick={onSelectUser}
            onRemove={onRemoveUser}
            isRecent={recentSearches.includes(u.username)}
          />
        ));
    }
  };

  const SearchInput = () => {
    const searchButtonRef = useRef<HTMLButtonElement | null>(null);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{ width: "100%" }}
      >
        <InputGroup size="md">
          <Input
            type="search"
            enterKeyHint="search"
            placeholder="Search for users"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            color="topFive.primary"
            _placeholder={{ color: "gray.300", fontWeight: 600 }}
            ref={searchInputRef}
            fontWeight={500}
            bg="white"
            borderRadius="xl"
            autoFocus
          />
          <InputRightElement>
            <Button
              h="1.75rem"
              size="sm"
              type="submit"
              mr="8px"
              disabled={isLoading}
              ref={searchButtonRef}
            >
              {isLoading ? (
                <Spinner size="xs" />
              ) : (
                <Icon as={FaMagnifyingGlass} color="gray.700" size="1em" />
              )}
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
    );
  };

  return (
    <GradientBackground>
      <Header hideSearch />
      <Center px={3} pb="1em">
        <VStack width="100%" maxW="450px" spacing={3}>
          <SearchInput />
          {getUserRows()}
        </VStack>
      </Center>
    </GradientBackground>
  );
};

export default ExplorePage;
