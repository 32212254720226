import {
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React from "react";
import { ListItem } from "src/types/list";
import TruncatedTitle from "../Utils/TruncatedTitle";

const ListGridItem: React.FC<{
  item: ListItem;
  rank: number;
  imageHeight?: any;
  bgColor?: string;
  borderRadius?: boolean;
}> = ({ item, rank, imageHeight, bgColor, borderRadius }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemTitlesEnabled = useFeatureFlagEnabled("itemTitles");
  return (
    <GridItem width="100%" minWidth="0" pos="relative" onClick={onOpen}>
      <Flex gap="0.5em" align="center" direction="column-reverse">
        {itemTitlesEnabled && (
          <TruncatedTitle
            bgColor={bgColor}
            title={item.title}
            rank={rank}
            subtitle={item.subtitle}
          />
        )}
        <Image
          src={item.imageURI}
          alt={item.title}
          w="100%"
          h={imageHeight ?? "5.5em"}
          objectFit="cover"
          boxShadow="md"
          background="white"
          borderRadius={borderRadius ? "4px" : undefined}
        />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay onClick={onClose} backdropFilter="blur(8px)" />
        <ModalContent
          bg="transparent"
          boxShadow="none"
          borderRadius="none"
          px="1em"
        >
          <ModalBody>
            <Center h="100%">
              <VStack>
                <Image
                  src={item.imageURI}
                  alt={item.title}
                  w={"12em"}
                  objectFit="cover"
                  boxShadow="md"
                  background="white"
                  borderRadius={
                    localStorage.getItem("borderRadiusDisabled") === "true"
                      ? undefined
                      : "4px"
                  }
                  mb="0.5em"
                />
                <Heading color="white" fontSize="1.4em" textAlign="center">
                  {item.title}
                </Heading>
                {item.subtitle && (
                  <Heading
                    color="topFive.gray.light"
                    fontSize="1em"
                    textAlign="center"
                  >
                    {item.subtitle}
                  </Heading>
                )}
              </VStack>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </GridItem>
  );
};

const ListGrid: React.FC<{
  items: ListItem[];
  tallImages?: boolean;
  imageHeight?: any;
  columns?: number;
  bgColor?: string;
  borderRadius?: boolean;
}> = ({ items, imageHeight, tallImages, columns, bgColor, borderRadius }) => {
  return (
    <Grid templateColumns={`repeat(${columns ?? 5}, 1fr)`} gap={2} width="100%">
      {items.map((item, index) => (
        <ListGridItem
          rank={index + 1}
          item={item}
          key={index}
          imageHeight={
            !!imageHeight
              ? imageHeight
              : tallImages
              ? { base: "5.5em", sm: "6.5em" }
              : { base: "3.9em", sm: "5em" }
          }
          bgColor={bgColor}
          borderRadius={borderRadius}
        />
      ))}
    </Grid>
  );
};

export default ListGrid;
